<!-- <nb-card>
    <nb-card-body>
  
      <nb-tabset fullWidth>
        <nb-tab tabTitle="Interest Rate type"> 
          <nb-card-body class="example-items-col">
            <form [formGroup]="interesetdetails"> 
              <h1>Interest Rate type</h1>
              <hr>
              <div class="row">
                <div class="col">
                  <label class="required label">Branch Code</label>
                  <input type="number" class="form-control" name="branch_code" formControlName="branch_code" placeholder="Enter Branch code"
                    [ngClass]="{ 'is-invalid': submitted && f.branch_code.errors }">
                  <div *ngIf="submitted && f.branch_code.errors" class="invalid-feedback">
                    <div *ngIf="f.branch_code.errors.required">Branch Code is required</div>
                  </div>
                </div>
                <div class="col">
                  <label class="required label">Rate Type Code</label>
                  <input type="text" class="form-control" name="rate_type_code" formControlName="rate_type_code" placeholder="Enter Rate Type Code"
                    [ngClass]="{ 'is-invalid': submitted && f.rate_type_code.errors }">
                  <div *ngIf="submitted && f.rate_type_code.errors" class="invalid-feedback">
                    <div *ngIf="f.rate_type_code.errors.required">Rate Type Code is required</div>
                  </div>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col">
                  <label class="required label">Interest Type</label>
                  <input type="text" class="form-control" name="interest_type" formControlName="interest_type" placeholder="Interest Type"
                    [ngClass]="{ 'is-invalid': submitted && f.interest_type.errors }">
                  <div *ngIf="submitted && f.interest_type.errors" class="invalid-feedback">
                    <div *ngIf="f.interest_type.errors.required"> Interest Type is required</div>
                  </div>
                </div>
                <div class="col">
                  <label class="required label">Transcation code</label>
                  <input type="text" class="form-control" name="transaction_code" formControlName="transaction_code" placeholder="Transcation code"
                    [ngClass]="{ 'is-invalid': submitted && f.transaction_code.errors }">
                  <div *ngIf="submitted && f.transaction_code.errors" class="invalid-feedback">
                    <div *ngIf="f.transaction_code.errors.required">Transcation code is required</div>
                  </div>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col">
                  <label class="required label">Start Date</label>
                  <input type="date" class="form-control" name="start_date" formControlName="start_date"
                    [ngClass]="{ 'is-invalid': submitted && f.start_date.errors }">
                  <div *ngIf="submitted && f.start_date.errors" class="invalid-feedback">
                    <div *ngIf="f.start_date.errors.required">Start Date is required</div>
                  </div>
                </div>
                <div class="col">
                  <label class="required label">End Date</label>
                  <input type="date" class="form-control" name="end_date" formControlName="end_date"
                    [ngClass]="{ 'is-invalid': submitted && f.end_date.errors }">
                  <div *ngIf="submitted && f.end_date.errors" class="invalid-feedback">
                    <div *ngIf="f.end_date.errors.required">End Date is required</div>
                  </div>
                </div>
              </div>
              <br>
              <div class="row mt-4">
                <label class="record_stat">Record Stat</label>
                <div class="col">
                  <label class="switch">
                    <input type="checkbox" checked>
                    <span class="slider round"></span>
                  </label>
                </div>
                <label class="Auth_stat">Auth Stat</label>
                <div class="col">
                  <label class="switch">
                    <input type="checkbox" checked>
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
  
              <!-- <button nbButton class="float-right ml-3 appearance-hero status-primary" (click)="interestview()">
                Back
              </button>
              <button nbButton class="float-right status-danger" (click)="onReset()">Reset</button>
              <button nbButton class="float-right mr-3 appearance-hero status-success" (click)="onSubmit()">Create</button> -->
            <!-- </form>
          </nb-card-body>
        </nb-tab>
  
  
        <nb-tab tabTitle="Interest Master">
          <nb-card-body class="example-items-col">
            <form [formGroup]="interesetdetails">
              <h6>Interest Master</h6>
              <hr>
              <div class="row">
                <div class="col">
                  <label class="required label">Branch Code</label>
                  <input type="text" class="form-control" name="branch_code1" formControlName="branch_code1" placeholder="Branch Code"
                    [ngClass]="{ 'is-invalid': submitted && f.branch_code1.errors }">
                  <div *ngIf="submitted && f.branch_code1.errors" class="invalid-feedback">
                    <div *ngIf="f.branch_code1.errors.required"> Branch Code is required</div>
                  </div>
                </div>
                <div class="col">
                  <label class="required label">Product Code</label>
                  <input type="text" class="form-control" name="product_code" formControlName="product_code" placeholder="Product Code"
                    [ngClass]="{ 'is-invalid': submitted && f.product_code.errors }">
                  <div *ngIf="submitted && f.product_code.errors" class="invalid-feedback">
                    <div *ngIf="f.product_code.errors.required"> Product Code is required</div>
                  </div>
                </div>
                <div class="col">
                  <label class="required label">Rate Code</label>
                  <input type="text" class="form-control" name="rate_code" formControlName="rate_code" placeholder="Rate Code"
                    [ngClass]="{ 'is-invalid': submitted && f.rate_code.errors }">
                  <div *ngIf="submitted && f.rate_code.errors" class="invalid-feedback">
                    <div *ngIf="f.rate_code.errors.required">Rate Code is required</div>
                  </div>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col">
                  <label class="required label">Interest Rate</label>
                  <input type="text" class="form-control" name="interest_rate" formControlName="interest_rate" placeholder="Interest Rate"
                    [ngClass]="{ 'is-invalid': submitted && f.interest_rate.errors }">
                  <div *ngIf="submitted && f.interest_rate.errors" class="invalid-feedback">
                    <div *ngIf="f.interest_rate.errors.required">Interest Rate is required</div>
                  </div>
                </div>
                <div class="col">
                  <label class="required label">Interest Type code</label>
                  <input type="text" class="form-control" name="interest_type_code" formControlName="interest_type_code" placeholder="Interest Type code"
                    [ngClass]="{ 'is-invalid': submitted && f.interest_type_code.errors }">
                  <div *ngIf="submitted && f.interest_type_code.errors" class="invalid-feedback">
                    <div *ngIf="f.interest_type_code.errors.required">Interest Type code is required</div>
                  </div>
                </div>
                <div class="col">
                  <label class="required label">Interest Basis</label>
                  <input type="number" class="form-control" name="interest_basis" formControlName="interest_basis" placeholder="Interest Basis"
                    [ngClass]="{ 'is-invalid': submitted && f.interest_basis.errors }">
                  <div *ngIf="submitted && f.interest_basis.errors" class="invalid-feedback">
                    <div *ngIf="f.interest_basis.errors.required">Interest Basis is required</div>
                  </div>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col">
                  <label class="required label">Accural Required</label>
                  <input type="text" class="form-control" name="accural_required" formControlName="accural_required" placeholder="Accural Required"
                    [ngClass]="{ 'is-invalid': submitted && f.accural_required.errors }">
                  <div *ngIf="submitted && f.accural_required.errors" class="invalid-feedback">
                    <div *ngIf="f.accural_required.errors.required"> Accural Required is required</div>
                  </div>
                </div>
                <div class="col">
                  <label class="required label">Accural CCY</label>
                  <input type="text" class="form-control" name="accural_CCY" formControlName="accural_CCY" placeholder="Accural CCY"
                    [ngClass]="{ 'is-invalid': submitted && f.accural_CCY.errors }">
                  <div *ngIf="submitted && f.accural_CCY.errors" class="invalid-feedback">
                    <div *ngIf="f.accural_CCY.errors.required"> Accural CCY is required</div>
                  </div>
                </div>
                <div class="col">
                  <label class="required label">Rate Code Usage</label>
                  <input type="text" class="form-control" name="rate_code_usage" formControlName="rate_code_usage" placeholder="Rate Code Usage"
                    [ngClass]="{ 'is-invalid': submitted && f.rate_code_usage.errors }">
                  <div *ngIf="submitted && f.rate_code_usage.errors" class="invalid-feedback">
                    <div *ngIf="f.rate_code_usage.errors.required">Rate Code Usage is required</div>
                  </div>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col">
                  <label class="required label">Description</label>
                  <input type="text" class="form-control" name="description" formControlName="description" placeholder="Description"
                    [ngClass]="{ 'is-invalid': submitted && f.description.errors }">
                  <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                    <div *ngIf="f.description.errors.required">Description is required</div>
                  </div>
                </div>
                <div class="col">
                  <label class="required label">Interest Type</label>
                  <input type="text" class="form-control" name="interest_type" formControlName="interest_type" placeholder="Interest Type"
                    [ngClass]="{ 'is-invalid': submitted && f.interest_type.errors }">
                  <div *ngIf="submitted && f.interest_type.errors" class="invalid-feedback">
                    <div *ngIf="f.interest_type.errors.required">Interest Type is required</div>
                  </div>
                </div>
                <div class="col">
                  <label class="required label">Stop Interest</label>
                  <input type="text" class="form-control" name="stop_interest" formControlName="stop_interest" placeholder="Stop Interest"
                    [ngClass]="{ 'is-invalid': submitted && f.stop_interest.errors }">
                  <div *ngIf="submitted && f.stop_interest.errors" class="invalid-feedback">
                    <div *ngIf="f.stop_interest.errors.required">Stop Interest is required</div>
                  </div>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col">
                  <label class="required label">Grace Period Unit</label>
                  <input type="text" class="form-control" name="grace_period_unit" formControlName="grace_period_unit" placeholder="Grace Period Unit"
                    [ngClass]="{ 'is-invalid': submitted && f.grace_period_unit.errors }">
                  <div *ngIf="submitted && f.grace_period_unit.errors" class="invalid-feedback">
                    <div *ngIf="f.grace_period_unit.errors.required">Grace Period Unit is required</div>
                  </div>
                </div>
                <div class="col">
                  <label class="required label">Grace Period Value</label>
                  <input type="text" class="form-control" name="grace_period_value" formControlName="grace_period_value" placeholder="Grace Period Value"
                    [ngClass]="{ 'is-invalid': submitted && f.grace_period_value.errors }">
                  <div *ngIf="submitted && f.grace_period_value.errors" class="invalid-feedback">
                    <div *ngIf="f.grace_period_value.errors.required">Grace Period Value is required</div>
                  </div>
                </div>
                <div class="col">
                  <label class="required label">Transaction code</label>
                  <input type="text" class="form-control" name="transaction_code1" formControlName="transaction_code1" placeholder="Transaction code"
                    [ngClass]="{ 'is-invalid': submitted && f.transaction_code1.errors }">
                  <div *ngIf="submitted && f.transaction_code1.errors" class="invalid-feedback">
                    <div *ngIf="f.transaction_code1.errors.required">Transcation Code is required</div>
                  </div>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col">
                  <label class="required label">Start Date</label>
                  <input type="date" class="form-control" name="start_date1" formControlName="start_date1"
                    [ngClass]="{ 'is-invalid': submitted && f.start_date1.errors }">
                  <div *ngIf="submitted && f.start_date1.errors" class="invalid-feedback">
                    <div *ngIf="f.start_date1.errors.required">Start Date is required</div>
                  </div>
                </div>
                <div class="col">
                  <label class="required label">End Date</label>
                  <input type="date" class="form-control" name="end_date1" formControlName="end_date1"
                    [ngClass]="{ 'is-invalid': submitted && f.end_date1.errors }">
                  <div *ngIf="submitted && f.end_date1.errors" class="invalid-feedback">
                    <div *ngIf="f.end_date1.errors.required">End Date is required</div>
                  </div>
                </div>
              </div>
              <br>
              <div class="row mt-4">
                <label class="record_stat">Record Stat</label>
                <div class="col">
                  <label class="switch">
                    <input type="checkbox" checked>
                    <span class="slider round"></span>
                  </label>
                </div>
                <label class="Auth_stat">Auth Stat</label>
                <div class="col">
                  <label class="switch">
                    <input type="checkbox" checked>
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
               </form>
          </nb-card-body>
        </nb-tab>
      </nb-tabset>
    </nb-card-body>
    <nb-card-footer class=" mt-5">
        <footer class="footer-fixed-bottom-wrap">
            
            <div class="footer-fixed-bottom">
                <form class="form-inline ml-5 mb-2">
                    <label for="maker">Maker:</label><br>
                    <input type="text" class="form-control" id="maker" name="maker"> 
                    <label for="checker">Checker:</label><br>
                    <input type="text" class="form-control" id="checker" name="checker"> 
                    <label for="maker_date">Maker date:</label><br>
                    <input type="text" class="form-control" id="maker_date" name="maker_date"> 
                    <label for="checker_date">Checker date:</label><br>
                    <input type="text" class="form-control" id="checker_date" name="checker_date">  
                    <label for="autorized">Autorized:</label><br>
                    <div class="form-check-inline">
                        <label class="form-check-label" for="radio1">
                        <input type="radio" class="form-check-input" id="radio1" name="optradio" value="option1" checked>Yes
                        </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label" for="radio2">
                        <input type="radio" class="form-check-input" id="radio2" name="optradio" value="option2">No
                        </label>
                    </div>
                </form>	 	
            </div>
            <div class="footer-fixed-bottom">
                    <div class="text-center mb-2 ml-5 ">
                        <button type="button" class="btn btn-success" (click)="onSubmit()">Create</button>
                            <button type="button" class="ml-3 btn btn-danger" (click)="onReset()">Reset</button>
                        <button type="button" class="ml-3 btn btn-primary" (click)="viewback()">Back</button>             
                    </div>
            </div>
            
        </footer>
    </nb-card-footer>
  </nb-card> --> -->