  <nb-card>
    <nb-card-header> <h6>Branch Register</h6></nb-card-header>
    <nb-card-body class="example-items-col">
        <form>         
            
            <br>                
            <br>
            <div class="row">
                <div class="col"> 
                <label>Bank ID</label>
                    <input type="text" class="form-control">
                </div>     
                <div class="col"> 
                    <label>Branch Name</label>
                      <input type="text" class="form-control">
                </div>
            </div>
            <br>    
            <div class="row">
              <div class="col">
                <label>Primary Address</label>  
            <textarea cols="3" rows="3" name="" id="" class="form-control"></textarea>
              </div>
              <div class="col">
                <label>Secondry Address</label>  
            <textarea cols="3" rows="3" name="" id="" class="form-control"></textarea>
              </div>
            </div>
            <br>            
            <div class="row">
              <div class="col">
                <label>City</label>  
                <input class="form-control">      
              </div>
              <div class="col">
                <label>State</label>
                <input class="form-control "> 
              </div>
            </div>
              <br>
            <div class="row">
            <div class="col">
              <label>Country</label>  
              <input class="form-control">
            </div>
            <div class="col">
            <label>Mobile No1</label>  
              <input class="form-control">
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col">
              <label>Mobile No2</label>  
              <input class="form-control ">
            </div>
            <div class="col">
              <label>Spec ID</label>  
              <input class="form-control ">
            </div>
          </div>
          <br>  
          <div class="row">
            <div class="col">
              <label>IFSC Code</label>  
              <input class="form-control ">
            </div>
            <div class="col">
              <label>MICR Code</label>  
              <input class="form-control ">
            </div>
          </div> 
          <br>  
          <div class="row">
            <div class="col">
              <label>Vault ID</label>  
              <input class="form-control ">
            </div>
            <div class="col">
              <label>Branch ID</label>  
              <input class="form-control ">
            </div>
          </div> 
          <br>  
          <div class="row">
            <div class="col">
              <label>Setting ID</label>  
              <input class="form-control ">
            </div>
          </div> 
          <br>  
            <button nbButton class="float-right">Create</button>
          </form>
    </nb-card-body>
</nb-card>
