import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { HelpersComponent } from "../../helpers/helpers.component";

@Injectable({
  providedIn: "root",
})
export class BankService {
  private headers = new HttpHeaders();
  [x: string]: any;

  postdata: any;

  constructor(
    private _http: HttpClient,
    private _helperComponent: HelpersComponent
  ) {}
  private _bankServiceURL = "http://loadbalancer.danfishel.com/BankService";
  private _documentServiceURL = "http://loadbalancer.danfishel.com/DocumentService";

  private handleError(errorReponse: HttpErrorResponse) {
    console.log("Client side error ", errorReponse.error.message);

    return throwError(errorReponse.error.message);
  }

  getBankCount(formData): Observable<any> {
    return this._http.post<any>(
      this._bankServiceURL + "/api/v1/get_bank_count",
      formData
    );
  }
  //bankothermaintenance
  get_all_bankothermaintenance(): Observable<any> {
    return this._http.get<any>(
      this._bankServiceURL + " /api/v1/getall_bankothermaint"
    );
  }

  getBankOtherMaintenance(bank_id): Observable<any> {
    return this._http.get<any>(
      this._bankServiceURL + "/api/v1/get_bankothermaint/" + bank_id
    );
  }
  postbankothermaintenance(formData): Observable<any> {
    console.log(formData);

    return this._http.post<any>(
      this._bankServiceURL + "/api/v1/create_bankothermaint",
      formData
    );
  }
  bankothermaintenanceUpdate(bank_id, otherMaintData): Observable<any> {
    this.headers.append("Content-Type", "application/json");
    this.headers.append("Accept", "application/json");
    this.headers.append("Access-Control-Allow-Headers", "*");
    return this._http.put<any>(
      this._bankServiceURL + "/api/v1/update_bankothermaint/" + bank_id,
      otherMaintData,
      { headers: this.headers }
    );
  }
  deletebankothermaintenance(bank_id): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };
    return this._http.delete<any>(
      this._bankServiceURL + "/api/v1/delete_bankothermaint/" + bank_id,
      httpOptions
    );
  }

  //bankmastermaintenance
  get_all_bankmastermaintenance(formData): Observable<any> {
    return this._http.post<any>(
      this._bankServiceURL + "/api/v1/getbanksfor_superadmin",
      formData
    );
  }

  create_bank_license(formData): Observable<any> {
    return this._http.post<any>(
      this._bankServiceURL + "/api/v1/create_banklicensemang",
      formData
    );
  }

  getbankmastermaintenance(bank_id, code): Observable<any> {
    return this._http.post<any>(
      this._bankServiceURL + "/api/v1/get_bankmaintmst/" + bank_id,
      code
    );
  }

  postbankmastermaintenance(formData): Observable<any> {
    this.headers.append("Content-Type", "application/json");
    this.headers.append("Accept", "application/json");
    this.headers.append("Access-Control-Allow-Headers", "*");
    console.log(formData);

    return this._http.post(
      this._bankServiceURL + "/api/v1/bankregister",
      formData,
      { headers: this.headers }
    );
  }
  bankmastermaintenanceUpdate(bank_id, masterData): Observable<any> {
    this.headers.append("Content-Type", "application/json");
    this.headers.append("Accept", "application/json");
    this.headers.append("Access-Control-Allow-Headers", "*");
    console.log(masterData);

    return this._http.put<any>(
      this._bankServiceURL + "/api/v1/update_bankmaintmst/" + bank_id,
      masterData,
      { headers: this.headers }
    );
  }
  deletebankmastermaintenance(bank_id): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };
    return this._http.delete<any>(
      this._bankServiceURL + "/api/v1/delete_bankmaintmst/" + bank_id,
      httpOptions
    );
  }

  // Register Bank via Super Admin Dashboard
  bankRegisterForSuperAdmin(formData): Observable<any> {
    this.headers.append("Content-Type", "application/json");
    this.headers.append("Accept", "application/json");
    this.headers.append("Access-Control-Allow-Headers", "*");
    console.log(formData);
    return this._http.post(
      this._bankServiceURL + "/api/v1/bankregisterfor_superadmin",
      formData,
      { headers: this.headers }
    );
  }

  //banklicensemaintenance
  get_all_licensemaintenance(): Observable<any> {
    return this._http.get<any>(
      this._bankServiceURL + "/api/v1/getall_banklicensemang"
    );
  }

  getlicensemaintenance(license_id): Observable<any> {
    return this._http.get<any>(
      this._bankServiceURL + "/api/v1/get_banklicensemang/" + license_id
    );
  }
  postbanklicensemaintenance(formData): Observable<any> {
    this.headers.append("Content-Type", "application/json");
    this.headers.append("Accept", "application/json");
    this.headers.append("Access-Control-Allow-Headers", "*");
    console.log(formData);
    return this._http.post<any>(
      this._bankServiceURL + "/api/v1/create_banklicensemang",
      formData,
      { headers: this.headers }
    );
  }
  licensemaintenanceUpdate(license_id, licData): Observable<any> {
    this.headers.append("Content-Type", "application/json");
    this.headers.append("Accept", "application/json");
    this.headers.append("Access-Control-Allow-Headers", "*");
    console.log(licData);
    return this._http.put<any>(
      this._bankServiceURL + "/api/v1/update_banklicensemang/" + license_id,
      licData,
      { headers: this.headers }
    );
  }
  deletelicensemaintenance(license_id): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };
    return this._http.delete<any>(
      this._bankServiceURL + "/api/v1/delete_banklicensemang/" + license_id,
      httpOptions
    );
  }

  // bankllimit
  get_all_bankllimit(bnkLimit): Observable<any> {
    return this._http.post<any>(
      this._bankServiceURL + "/api/v1/getall_banklimits",
      bnkLimit
    );
  }

  getbankllimit(bank_id, bankData): Observable<any> {
    return this._http.post<any>(
      this._bankServiceURL + "/api/v1/get_banklimits/" + bank_id,
      bankData
    );
  }
  postbankllimit(formData): Observable<any> {
    this.headers.append("Content-Type", "application/json");
    this.headers.append("Accept", "application/json");
    this.headers.append("Access-Control-Allow-Headers", "*");
    console.log(formData);
    return this._http.post<any>(
      this._bankServiceURL + "/api/v1/create_banklimits",
      formData,
      { headers: this.headers }
    );
  }
  bankllimitUpdate(bank_id, bnkLimitData): Observable<any> {
    this.headers.append("Content-Type", "application/json");
    this.headers.append("Accept", "application/json");
    this.headers.append("Access-Control-Allow-Headers", "*");
    console.log(bnkLimitData);
    return this._http.put<any>(
      this._bankServiceURL + "/api/v1/update_banklimits/" + bank_id,
      bnkLimitData,
      { headers: this.headers }
    );
  }
  deletebankllimit(bank_id): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };
    return this._http.delete<any>(
      this._bankServiceURL + "/api/v1/delete_banklimits/" + bank_id,
      httpOptions
    );
  }

  uploadBankFiles(formData): Observable<any> {
    const headers = new HttpHeaders().set('Change-Content-Type', 'true');
    // this.headers.append("Content-Type", "application/json");
    // this.headers.append("Accept", "application/json");
    // this.headers.append("Access-Control-Allow-Headers", "*");
    console.log(formData);
    return this._http.post(
      this._documentServiceURL + "/api/v1/bankfiles_upload",
      formData,
      { headers: headers }
    );
  }

  getAllBankFiles(bankData): Observable<any> {
    return this._http.post<any>(
      this._documentServiceURL + "/api/v1/getallbankfiles",
      bankData
    );
  }

  getParticularBankFile(formData) {
    return this._http.post<any>(
      this._documentServiceURL +
        "/api/v1/viewpartbankfiles",
      formData,
      {responseType: 'arraybuffer' as 'json'}
    );
  }
}

// bankcodemaintenance(formData) {

//   return this._http.post<any>(this._bankServiceURL + ' /api/v1/create_bankcdmaint ', formData)
// }
// bankothermaintenance(formData) {

//   return this._http.post<any>(this._bankServiceURL + '  /api/v1/create_bankothermaint ', formData)
// }
// banklicensemaintenance(formData) {

//   return this._http.post<any>(this._bankServiceURL + ' /api/v1/create_banklicensemang ', formData)
// }
// bankstatus(formData) {

//   return this._http.post<any>(this._bankServiceURL + '  /api/v1/create_bankstatus', formData)
// }
// bankmastermaintenance(formData) {

//   return this._http.post<any>(this._bankServiceURL + '/api/v1/create_bankmaintmst ', formData)
// }

// getbankcodemaintenance() {

//   return this._http.get<any>(this._bankServiceURL + ' /api/v1/get_bankcdmaint/{bank_code} ')
// }
// getbankmastermaintenance() {

//   return this._http.get<any>(this._bankServiceURL + '  /api/v1/get_bankmaintmst/{bank_code} ')
// }

// getbankothermaintenance() {

//   return this._http.get<any>(this._bankServiceURL + ' /api/v1/get_bankothermaint/{bank_code} ')
// }
// getlicensemaintenance() {

//   return this._http.get<any>(this._bankServiceURL + ' /api/v1/get_banklicensemang/{bank_code} ')
// }
// getbankstatus() {

//   return this._http.get<any>(this._bankServiceURL + '/api/v1/get_bankstatus/{status_code}')
// }

// get_all_bankcodemaintenance() {

//   return this._http.get<any>(this._bankServiceURL + '  /api/v1/getall_bankcdmaint ')
// }
// get_all_bankmastermaintenance() {

//   return this._http.get<any>(this._bankServiceURL + '  /api/v1/getall_bankmaintmst ')
// }

// get_all_bankothermaintenance() {

//   return this._http.get<any>(this._bankServiceURL + '  /api/v1/getall_bankothermaint  ')
// }
// get_all_licensemaintenance() {

//   return this._http.get<any>(this._bankServiceURL + '  /api/v1/getall_banklicensemang ')
// }
// get_all_bankstatus() {

//   return this._http.get<any>(this._bankServiceURL + ' /api/v1/getall_bankstatus ')
// }

// bankcodemaintenanceUpdate( formdata) {
//   return this._http.put<any>(this._bankServiceURL + ' /api/v1/update_bankcdmaint/{bank_code} ',  formdata)

// }
// bankmastermaintenanceUpdate( formdata) {
//   return this._http.put<any>(this._bankServiceURL + '  /api/v1/update_bankmaintmst/{bank_code} ', formdata)

// }
// bankothermaintenanceUpdate( formdata) {
//   return this._http.put<any>(this._bankServiceURL + ' /api/v1/update_bankothermaint/{bank_code} ', formdata)

// }

// licensemaintenanceUpdate( formdata) {
//   return this._http.put<any>(this._bankServiceURL + '  /api/v1/update_banklicensemang/{bank_code} ', formdata)

// }

// bankstatusUpdate( formdata) {
//   return this._http.put<any>(this._bankServiceURL + '  /api/v1/update_bankstatus/{status_code} ', formdata)

// }

// deletebankcodemaintenance(formdata) {
//   return this._http.delete<any>(this._bankServiceURL + ' /api/v1/delete_bankcdmaint/{bank_code}' +  formdata)

// }
// deletebankmastermaintenance(formdata) {
//   return this._http.delete<any>(this._bankServiceURL + ' /api/v1/delete_bankmaintmst/{bank_code} ' +  formdata)

// }
// deletebankothermaintenance(formdata) {
//   return this._http.delete<any>(this._bankServiceURL + ' /api/v1/delete_bankothermaint/{bank_code}' +  formdata)

// }
// deletelicensemaintenance(formdata) {
//   return this._http.delete<any>(this._bankServiceURL + ' /api/v1/delete_banklicensemang/{bank_code} ' +  formdata)

// }
// deletebankstatus(formdata) {
//   return this._http.delete<any>(this._bankServiceURL + ' /api/v1/delete_bankstatus/{status_code} ' +  formdata)

// }

// url = 'http://localhost:65389/Api/Employee';
// constructor(private http: HttpClient) { }
// getAllEmployee(): Observable<Employee[]> {
//   return this.http.get<Employee[]>(this.url + '/AllEmployeeDetails');
// }
// getEmployeeById(employeeId: string): Observable<Employee> {
//   return this.http.get<Employee>(this.url + '/GetEmployeeDetailsById/' + employeeId);
// }
// createEmployee(employee: Employee): Observable<Employee> {
//   const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) };
//   return this.http.post<Employee>(this.url + '/InsertEmployeeDetails/',
//   employee, httpOptions);
// }
// updateEmployee(employee: Employee): Observable<Employee> {
//   const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) };
//   return this.http.put<Employee>(this.url + '/UpdateEmployeeDetails/',
//   employee, httpOptions);
// }
// deleteEmployeeById(employeeid: string): Observable<number> {
//   const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) };
//   return this.http.delete<number>(this.url + '/DeleteEmployeeDetails?id=' +employeeid,
// httpOptions);
// }
