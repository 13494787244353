  <nb-card>
    <nb-card-header class="FeeHeader">
      <h1>Fee Details</h1> 
      <button nbButton class="float-right appearance-hero status-success" 
        >Create Fee Details</button>
    </nb-card-header>
    <nb-card-body class="example-items-col mt-4 ">
      <nb-tabset fullWidth>
        <nb-tab tabTitle="Fee type">
          <table>
            <tr>
              <th>Branch Code</th>
              <th>Fee Type Code</th>
              <th>Fee Type</th>
              <th>Fee Type Description</th>
              <th>Action</th>
  
            </tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
  
             <td>
  
              <button nbButton class="float-right appearance-hero status-primary"
             >Edit View</button>
            
            </td>
  
            </table>      
          </nb-tab>
        <nb-tab tabTitle="Fee Master ">
          <table>
            <tr>
              <th>Branch Code</th>
              <th>Product Code</th>
              <th>Fee Code</th>
              <th>Fee Name</th>
              <th>Fee CCY</th>
              <th>Rate Code Usage</th>
              <th>Interest Type</th>
              <th>Stop Interest</th>
              <th>Action</th>
            </tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          
            <td>
              <button nbButton class="float-right appearance-hero status-primary"
              >Edit View</button>
            </td>
            </table>      
          </nb-tab>
       </nb-tabset>
    </nb-card-body>
  </nb-card> 
  
  
 