import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from '../../../auth/components/login/authencation.service';
import { HelpersComponent } from '../../../helpers/helpers.component';
import { InterestformService } from '../interestform.service';

@Component({
  selector: 'ngx-interests-form',
  templateUrl: './interests-form.component.html',
  styleUrls: ['./interests-form.component.scss']
})
export class InterestsFormComponent implements OnInit {
  interestDetailsForm: any;
  interestRegisterForm: any;
  interestpostextradata: any;
  interestMethod: any;
  NextBtn: boolean;
  submitted: boolean;
  interestpostdata: any;

  constructor(
    private spinner: NgxSpinnerService,
    private _helperComponent: HelpersComponent,
    private formBuilder: FormBuilder,
    private _interestservice: InterestformService,
    private router: Router,
    config: NgbCarouselConfig,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
  }

  getinterestvalue() {
    this.spinner.show();
    return this._interestservice
      .allInterestGet()
      .subscribe(
        (res) => {
          console.log(res, "321");
          this._interestservice.push(res);
        },
        (err) => {
          this._helperComponent.showToast("danger", err);
        }
      )
    
  }
  get f() {
    return this.interestMethod.controls;
  }
  get bd() {
    return this.interestDetailsForm.controls;
  }
  onReset() {
    this.interestMethod.reset();
  }

  onInterestSubmit() {
    // this.bdSubmit = true;

    // stop here if form is invalid
    if (this.interestDetailsForm.invalid) {
      return;
    }

    let formData = new FormData();
    let _Formdata = this.interestRegisterForm.value;
    this.spinner.show();
    // Form data
    Object.entries(_Formdata).forEach(([key, values]) => {
      this.interestpostextradata[key] = values;
    });

    this._interestservice
      .interestUpdate(this.interestid, this.interestpostextradata)
      .subscribe(
        (res) => {
          console.log(res + "done");
          this.spinner.hide();
          this.router.navigate(["/pages/layout/branchview"]);
        },
        (err) => {
          this.spinner.hide();
          this._helperComponent.showToast("danger", err);
        }
      )
      .add(() => {
        this.spinner.hide();
      });

  }
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid

    if (this.interestMethod.invalid) {
      return;
    }
    this.spinner.show();
    let formData = new FormData();
    let _Formdata = this.interestMethod.value;

    // Form data

    Object.entries(_Formdata).forEach(([key, values]) => {
      this.interestpostdata[key] = values;
    });

    this._interestservice
      .interestMethod(this.interestpostdata)
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res[1]["statuscode"] == 200) {
            this._helperComponent.showToast("success", res["message"]);
            this.NextBtn = true;
            this.interestMethod.disable();
            this.interestid = res[0]["BranchId"];
          }
        },
        (err) => {
          this.spinner.hide();
          this._helperComponent.showToast("danger", err["message"]);
          this.NextBtn = false;
        }
      )
      .add(() => {
        this.spinner.hide();
      });

  }





  interestid(interestid: any, interestpostextradata: any) {
    throw new Error('Method not implemented.');
  }

  



}
