
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { AlertService } from '../../../helpers/alert.service';
import { HelpersComponent } from '../../../helpers/helpers.component';
import { ApiService } from '../../../helpers/notification.service';
import { AuthenticationService } from './authencation.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  
  loginForm: FormGroup;
  bodyData: any;
  loading = false;
  submitted = false;
  returnUrl: string;
  // ErrorCard = false;
  errorCardUser = false;
  errorCardPwd = false;
  logginError = '';
  
  constructor(private apiService: ApiService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private _helperComponent: HelpersComponent,
    private spinner: NgxSpinnerService, config: NgbCarouselConfig,
    private authenticationService: AuthenticationService) {

    // if (this.authenticationService.currentUserValue) {
    //   this.router.navigate(['/pages']);
    // }
    config.interval = 8000;
    config.wrap = true;
    config.keyboard = false;
    config.pauseOnHover = true;
  }

  ngOnInit(): void {
    console.log('Login');
    if (sessionStorage.getItem('ACCESS_TOKEN') !== null) {
      // role not authorised so redirect to home page
      this.router.navigate([this.returnUrl]);
    }

    this.loginForm = this.formBuilder.group({
      username: new FormControl("", [Validators.required]),
      // , Validators.pattern("^[a-zA-Z0-9]*$")
      password: ['', [Validators.required]],
    });

  //   private validateUsername(): ValidatorFn {
  //     return (control: AbstractControl): {[key: string]: any} => {
  //       this.authenticationService.checkUsername(control)
  //         .subscribe(
  //           ({data}) => {
  //             let res: string = data;
  //             if (res === control.value) {
  //               return {'alreadyExist': true};
  //             } else {
  //               return null
  //             }
  //           },
  //           (error) => {
  //             console.log(error);
  //           }
  //         )
  //     }
  // }

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/epinpage'
    this.authenticationService.logout();
  }
  
  get f() {
    return this.loginForm.controls;
  }

  onLoginSubmit() {
    this.submitted = true;
    // reset alerts on submit
    if (this.loginForm.invalid) {
      return;
    }
    // let bodyForm = this.loginForm.value;
    // this.spinner.show()
    // Object.entries(bodyForm).forEach(([key, values])=> {
    //   this.bodyData[key] = values;
    // })
    this.bodyData = {
      "transaction_code": 'USR_GID_USR_308',
      "UI_page_id": '1234a'
    }

    this.authenticationService
      .login(this.f.username.value, this.f.password.value, this.bodyData)
      .pipe(first())
      .subscribe(
        (data) => {
          // localStorage.setItem('ROLES', JSON.stringify(data['employee designation']));
          localStorage.setItem('Access-Token', JSON.stringify(data['access-token']));
          localStorage.setItem('Bank_Name', JSON.stringify(data['username']));
          this.spinner.hide()
          this.router.navigate([this.returnUrl]);
        },
        (error) => {
          this.logginError = error;
          if(error==="username is not found"){
            this.errorCardUser = true
          }else{
            this.errorCardUser = false
          }
          if(error==="password is incorrect"){
            this.errorCardPwd = true
          }else{
            this.errorCardPwd = false
          }

        }
      )
      .add(() => {
        this.spinner.hide();
      });
  }

  onReset() {
    this.submitted = false;
    this.loginForm.reset();
  }

  // get getFormValidation() {
  //   return this.loginForm.controls;
  // }
}
