import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { HelpersComponent } from '../../helpers/helpers.component';

@Injectable({
  providedIn: 'root'
})
export class BranchService {
  [x: string]: any;
  postdata: any;
  private headers = new HttpHeaders();

  constructor(private _http: HttpClient, private _helperComponent: HelpersComponent
    ) { }
  private _BranchServiceURL = "http://loadbalancer.danfishel.com/BranchService";
  private _AccountServiceURL = "http://loadbalancer.danfishel.com/CustomerAccountService";
   

  private handleError(errorReponse: HttpErrorResponse) {


    console.log('Client side error ', errorReponse.error.message)

    return throwError(errorReponse.error.message)

  }

  // Branch Count for Super Admin wrt a Selected Bank
  getBranchCount(bank_id, formData): Observable<any> {
    return this._http.post<any>(this._BranchServiceURL + '/api/v1/getbranchcount_superadmin/' + bank_id, formData);
  }
  // Branches for Super Admin wrt a Selected Bank
  getBranchForSA(bank_id, formData): Observable<any> {
    return this._http.post<any>(this._BranchServiceURL + '/api/v1/getbranchfor_superadmin/' + bank_id, formData);
  }

  createTillMaint(formData){
    return this._http.post<any>(this._BranchServiceURL + '/api/v1/create_tillmaint', formData);
  }

  getTotalCreditInfo(formData){
    return this._http.post<any>(this._AccountServiceURL + '/api/v1/get_credit_transaction_bybrn', formData);
  }

  getTotalDebitInfo(formData){
    return this._http.post<any>(this._AccountServiceURL + '/api/v1/get_debit_transaction_bybrn', formData);
  }

  getAllTillMaint(){
    const formData={
      UI_page_id: "TILLMAIN001",
      transaction_code: "BRN_CRE_USR_106"
    }
    return this._http.post<any>(this._BranchServiceURL + '/api/v1/getall_tillmaint', formData);
  }

  getTodayTillBalance(formData){
    return this._http.post<any>(this._BranchServiceURL + '/api/v1/get_today_till_for_branch', formData);
  }

  getTodayTillCloseBalance(formData){
    return this._http.post<any>(this._BranchServiceURL + '/api/v1/get_today_closed_till_for_branch', formData);
  }

 // branch
  getAllBranch(brnData): Observable<any> {  
    return this._http.post<any>(this._BranchServiceURL + '/api/v1/getall_branch',brnData);  
  }  

  getBranch(branch_id,branchData): Observable<any> { 
    
    return this._http.post<any>(this._BranchServiceURL + '/api/v1/get_branch/' + branch_id,branchData);  
  }  
  postbranch(formData): Observable<any> {  
    
    console.log(formData); 
    return this._http.post<any>(this._BranchServiceURL + '/api/v1/create_branch',  
    formData);  
  }  
  branchUpdate(branch_id, brnData): Observable<any> {  
    
    console.log(brnData); 
    return this._http.put<any>(this._BranchServiceURL + '/api/v1/update_branch/'+branch_id,brnData,{ headers: this.headers})  
  }  
  deletebranch(branch_id): Observable<any> {  
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) };  
    return this._http.delete<any>(this._BranchServiceURL + '/api/v1/delete_branch/' +branch_id,  
  httpOptions);  
  }  


  //branchtype
  getAllBranchtype(typData): Observable<any> {  
    return this._http.post<any>(this._BranchServiceURL + '/api/v1/getall_branchtype',typData);  
  }  

  getBranchType(brn_type_id,typData): Observable<any> { 
    
    return this._http.post<any>(this._BranchServiceURL + '/api/v1/get_branchtype/' + brn_type_id,typData);  
  }  
  postbranchtype(formData): Observable<any> {  
   
    console.log(formData);
   
    return this._http.post<any>(this._BranchServiceURL + '/api/v1/create_branchtype',  
    formData);  
  }  
  branchtypeUpdate(brn_type_id,typeData): Observable<any> {  
   
    console.log(typeData);
   
    return this._http.put<any>(this._BranchServiceURL + '/api/v1/update_branchtype/'+ brn_type_id,typeData)  
  }  
  deletebranchtype(brn_type_id): Observable<any> {  
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) };  
    return this._http.delete<any>(this._BranchServiceURL + '/api/v1/delete_branchtype/' +brn_type_id,  
  httpOptions);  
  }  

 //branchgroup
 getAllBranchgroup(fromData): Observable<any> {  
  return this._http.post<any>(this._BranchServiceURL + '/api/v1/getall_branchgrp',fromData);  
}  

getbranchgroup(branch_id,grpData): Observable<any> { 
  return this._http.post<any>(this._BranchServiceURL + '/api/v1/get_branchgrpdetails/'+branch_id,grpData);  
  // return this._http.post<any>(this._BranchServiceURL + '/api/v1/get_branchgrp/'+branch_id,grpData);  
}

getBranchGroupDetails(branch_grp_id, grpData): Observable<any>  {
  return this._http.post<any>(this._BranchServiceURL + '/api/v1/get_branchgrpms/'+ branch_grp_id, grpData); 
}

postbranchgroup(formData): Observable<any> {  
 
  console.log(formData);    
  return this._http.post<any>(this._BranchServiceURL + '/api/v1/create_branchgrp',  
  formData);  
}  
branchgroupUpdate(branch_id, formdata): Observable<any> {  
  
  console.log(formdata); 
  return this._http.put<any>(this._BranchServiceURL + '/api/v1/update_branchgrp/'+branch_id,formdata)  
}  
deletebranchgroup(branch_id): Observable<any> {  
  const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) };  
  return this._http.delete<any>(this._BranchServiceURL + '/api/v1/delete_branchgrp/' + branch_id,  
httpOptions);  
}

 //branchgroupmaster
 getAllBranchgroupmaster(grpMaster): Observable<any> {  
  return this._http.post<any>(this._BranchServiceURL + '/api/v1/getall_branchgrpms',grpMaster);  
}  

getbranchgroupmaster(branch_group_id,brnGrpMaster): Observable<any> {  
  
  return this._http.post<any>(this._BranchServiceURL + '/api/v1/get_branchgrpms/' + branch_group_id,brnGrpMaster);  
}  
postbranchgroupmaster(formData): Observable<any> {  
  
  console.log(formData);   
   return this._http.post<any>(this._BranchServiceURL + '/api/v1/create_branchgrpms',  
  formData);  
}  
branchgroupmasterUpdate(branch_group_id, grpMasterData): Observable<any> {  
 
  console.log(grpMasterData); 
  return this._http.put<any>(this._BranchServiceURL + '/api/v1/update_branchgrpms/'+branch_group_id,grpMasterData)  
}  
deletebranchgroupmaster(branch_group_id): Observable<any> {  
  const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) };  
  return this._http.delete<any>(this._BranchServiceURL + '/api/v1/delete_branchgrpms/' +branch_group_id,  
httpOptions);  
}


 //branchgroupcode
//  get_all_branchgroupcode(): Observable<any> {  
//   return this._http.get<any>(this._BranchServiceURL + '/api/v1/getall_branchgrpcd');  
// }  

// getbranchgroupcode(group_code_id): Observable<any> {  
//   return this._http.get<any>(this._BranchServiceURL + '/api/v1/get_branchgrpcd/'+group_code_id);  
// }  
// postbranchgroupcode(formData): Observable<any> {  
//   this.headers.append('Content-Type', 'application/json');
//   this.headers.append('Accept', 'application/json');
//   this.headers.append('Access-Control-Allow-Headers', '*');
//   console.log(formData);    return this._http.post<any>(this._BranchServiceURL + '/api/v1/create_branchgrpcd',  
//   formData,{ headers: this.headers});  
// }  
// branchgroupcodeUpdate(group_code_id, formdata): Observable<any> {  
//   this.headers.append('Content-Type', 'application/json');
//   this.headers.append('Accept', 'application/json');
//   this.headers.append('Access-Control-Allow-Headers', '*');
//   console.log(formdata); 
//   return this._http.put<any>(this._BranchServiceURL + '/api/v1/update_branchgrpcd/'+group_code_id,  formdata,{ headers: this.headers})  
// }  
// deletebranchgroupcode(group_code_id): Observable<any> {  
//   const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) };  
//   return this._http.delete<any>(this._BranchServiceURL + '/api/v1/delete_branchgrpcd/' + group_code_id,  
// httpOptions);  
// }


 //branchdate
  // get_all_branchdate(): Observable<any> {  
  //   return this._http.get<any>(this._BranchServiceURL + '/api/v1/getall_branchdate');  
  // }  

  // getbranchdate(branch_date_id): Observable<any> {  
  //   return this._http.get<any>(this._BranchServiceURL + '/api/v1/get_branchdate/'+branch_date_id);  
  // }  
  // postbranchdate(formData): Observable<any> {  
  //   this.headers.append('Content-Type', 'application/json');
  //   this.headers.append('Accept', 'application/json');
  //   this.headers.append('Access-Control-Allow-Headers', '*');
  //   console.log(formData);    return this._http.post<any>(this._BranchServiceURL + '/api/v1/create_branchdate',  
  //   formData,{ headers: this.headers});  
  // }  
  // branchdateUpdate(branch_date_id, formdata): Observable<any> {  
  //   this.headers.append('Content-Type', 'application/json');
  //   this.headers.append('Accept', 'application/json');
  //   this.headers.append('Access-Control-Allow-Headers', '*');
  //   console.log(formdata); 
  //   return this._http.put<any>(this._BranchServiceURL + '/api/v1/update_branchdate/'+branch_date_id,  formdata,{ headers: this.headers})  
  // }  
  // deletebranchdate(branch_date_id): Observable<any> {  
  //   const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) };  
  //   return this._http.delete<any>(this._BranchServiceURL + '/api/v1/delete_branchdate/' + branch_date_id,  
  // httpOptions);  
  // }



 }
