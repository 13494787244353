<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<nav class="navbar navbar-light justify-content-between">
  <img src="assets/images/Danfishel-logo.png" class="circle2" width="150" />
  <div class="register">
    <a routerLink="/login" routerLinkActive="active">
      <i class="fa fa-user"> sign-in</i></a
    >
  </div>
</nav>

<section class="registration-page">
  <div class="row">
    <div class="container col-md-6">
      <ngb-carousel>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <img
              class="w-100"
              src="assets/images/slide-1.png"
              alt="first slide"
            />
          </div>
          <!-- <div class="carousel-caption">
            <h3>First Slide</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </div> -->
        </ng-template>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <img
              class="w-100"
              src="assets/images/slide-2.png"
              alt="second slide"
            />
          </div>
          <!-- <div class="carousel-caption">
            <h3>Second Slide</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </div> -->
        </ng-template>
      </ngb-carousel>
    </div>

    <div class="col-md-6 registers">
      <form [formGroup]="bankRegisterForm" (ngSubmit)="licenseMaintenance()">
        <div class="pr-4">
          <h3>Register your Bank</h3>
          <small id="emailHelp" class="form-text text-muted"
            >Your details will never be shared with anyone.</small
          >
          <hr />
          <div class="row">
            <div class="col-6 form-group">
              <label class="form-group required">Bank Name</label>
              <input
                type="text"
                class="form-control"
                name="bank_name"
                formControlName="bank_name"
                [ngClass]="{
                  'is-invalid':
                    getFormValidation?.bank_name?.errors &&
                    (submitted || getFormValidation?.bank_name?.touched)
                }"
              />
              <!-- <div *ngIf="submitted && f.bank_name.errors" class="invalid-feedback">
              <div *ngIf="f.bank_name.errors.required">Bank Name is required</div>
            </div> -->
              <div
                class="invalid-feedback"
                *ngIf="
                  getFormValidation?.bank_name?.errors &&
                  (submitted || getFormValidation?.bank_name?.touched)
                "
              >
                <div *ngIf="getFormValidation?.bank_name?.errors.required">
                  Bank Name is required
                </div>
                <div *ngIf="getFormValidation?.bank_name?.errors?.pattern">
                  Numbers and special characters are not allowed in bank name
                </div>
              </div>
            </div>
            <div class="col-3 form-group">
              <label class="form-group required">Bank Code</label>
              <input
                type="string"
                class="form-control"
                name="bank_code"
                formControlName="bank_code"
                [ngClass]="{
                  'is-invalid':
                    getFormValidation?.bank_code?.errors &&
                    (submitted || getFormValidation?.bank_code?.touched)
                }"
              />
              <div
                class="invalid-feedback"
                *ngIf="
                  getFormValidation?.bank_code?.errors &&
                  (submitted || getFormValidation?.bank_code?.touched)
                "
              >
                <div *ngIf="getFormValidation?.bank_code?.errors">
                  Bank code is required
                </div>
              </div>
            </div>
            <div class="col-3 form-group">
              <label class="form-group required">Bank Iban Code</label>
              <input
                type="text"
                class="form-control"
                name="bank_iban_code"
                formControlName="bank_iban_code"
                [ngClass]="{
                  'is-invalid':
                    getFormValidation?.bank_iban_code?.errors &&
                    (submitted || getFormValidation?.bank_iban_code?.touched)
                }"
              />
              <div
                class="invalid-feedback"
                *ngIf="
                  getFormValidation?.bank_iban_code?.errors &&
                  (submitted || getFormValidation?.bank_iban_code?.touched)
                "
              >
                <div
                  *ngIf="getFormValidation?.bank_iban_code?.errors?.required"
                >
                  Bank Iban code is required
                </div>
                <div
                  *ngIf="getFormValidation?.bank_iban_code?.errors?.maxlength"
                >
                  Bank Iban code should be less than 8 characters.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6 form-group">
              <label class="form-group required">Bank Email</label>
              <input
                type="email"
                class="form-control"
                name="bank_email"
                formControlName="bank_email"
                [ngClass]="{
                  'is-invalid':
                    getFormValidation?.bank_email?.errors &&
                    (submitted || getFormValidation?.bank_email?.touched)
                }"
              />
              <!-- <div *ngIf="submitted && f.bank_email.errors" class="invalid-feedback">
              <div *ngIf="f.bank_email.errors.required">E-mail is required</div>
              <div *ngIf="f.bank_email.errors.email">Email must be a valid email address</div>
            </div> -->
              <div
                class="invalid-feedback"
                *ngIf="
                  getFormValidation?.bank_email?.errors &&
                  (submitted || getFormValidation?.bank_email?.touched)
                "
              >
                <div *ngIf="getFormValidation?.bank_email?.errors?.required">
                  E-mail is required
                </div>
                <div *ngIf="getFormValidation?.bank_email?.errors?.email">
                  Enter valid email address
                </div>
              </div>
            </div>
            <div class="col-3 form-group">
              <label class="form-group required">Reg Mobile</label>
              <input
                type="number"
                class="form-control"
                name="bank_reg_mobile"
                formControlName="bank_reg_mobile"
                [ngClass]="{
                  'is-invalid':
                    getFormValidation?.bank_reg_mobile?.errors &&
                    (submitted || getFormValidation?.bank_reg_mobile?.touched)
                }"
              />
              <div
                class="invalid-feedback"
                *ngIf="
                  getFormValidation?.bank_reg_mobile?.errors &&
                  (submitted || getFormValidation?.bank_reg_mobile?.touched)
                "
              >
                <div
                  *ngIf="getFormValidation?.bank_reg_mobile?.errors?.required"
                >
                  Mobile is required
                </div>
                <div
                  *ngIf="getFormValidation?.bank_reg_mobile?.errors?.pattern"
                >
                  Enter valid mobile number
                </div>
              </div>
            </div>
            <div class="col-3 form-group">
              <label class="required">Bank Short Name</label>
              <input
                type="text"
                class="form-control"
                name="bank_short_name"
                formControlName="bank_short_name"
                [ngClass]="{
                  'is-invalid': submitted && f.bank_short_name.errors
                }"
              />
              <div
                *ngIf="submitted && f.bank_short_name.errors"
                class="invalid-feedback"
              >
                <!-- <div *ngIf="f.bank_short_name.errors.required">Bank Short Name is required</div> -->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6 form-group">
              <label class="form-group required">Address line 1</label>
              <input
                type="text"
                class="form-control"
                name="address_ln_1"
                formControlName="address_ln_1"
                [ngClass]="{
                  'is-invalid':
                    getFormValidation?.address_ln_1?.errors &&
                    (submitted || getFormValidation?.address_ln_1?.touched)
                }"
              />
              <div
                class="invalid-feedback"
                *ngIf="
                  getFormValidation?.address_ln_1?.errors &&
                  (submitted || getFormValidation?.address_ln_1?.touched)
                "
              >
                <div *ngIf="getFormValidation?.address_ln_1?.errors?.required">
                  Address line 1 is required
                </div>
              </div>
            </div>
            <div class="col-6 form-group">
              <label class="form-group required">Address line 2</label>
              <input
                type="text"
                class="form-control"
                name="address_ln_2"
                formControlName="address_ln_2"
                [ngClass]="{
                  'is-invalid':
                    getFormValidation?.address_ln_2?.errors &&
                    (submitted || getFormValidation?.address_ln_2?.touched)
                }"
              />
              <div
                class="invalid-feedback"
                *ngIf="
                  getFormValidation?.address_ln_2?.errors &&
                  (submitted || getFormValidation?.address_ln_2?.touched)
                "
              >
                <div *ngIf="getFormValidation?.address_ln_2?.errors?.required">
                  Address line 2 is required
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6 form-group">
              <label class="required">Address line 3</label>
              <input
                type="text"
                class="form-control"
                name="address_ln_3"
                formControlName="address_ln_3"
                [ngClass]="{ 'is-invalid': submitted && f.address_ln_3.errors }"
              />
              <div
                *ngIf="submitted && f.address_ln_3.errors"
                class="invalid-feedback"
              >
                <!-- <div *ngIf="f.address_ln_3.errors.required">Address line 3 is required</div> -->
              </div>
            </div>
            <div class="col-6 form-group">
              <label class="required">Address line 4</label>
              <input
                type="text"
                class="form-control"
                name="address_ln_4"
                formControlName="address_ln_4"
                [ngClass]="{ 'is-invalid': submitted && f.address_ln_4.errors }"
              />
              <div
                *ngIf="submitted && f.address_ln_4.errors"
                class="invalid-feedback"
              >
                <!-- <div *ngIf="f.address_ln_4.errors.required">Address line 4 is required</div> -->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-3 form-group">
              <label class="form-group required">city</label>
              <input
                type="text"
                class="form-control"
                name="city"
                formControlName="city"
                [ngClass]="{
                  'is-invalid':
                    getFormValidation?.city?.errors &&
                    (submitted || getFormValidation?.city?.touched)
                }"
              />
              <div
                class="invalid-feedback"
                *ngIf="
                  getFormValidation?.city?.errors &&
                  (submitted || getFormValidation?.city?.touched)
                "
              >
                <div *ngIf="getFormValidation?.city?.errors?.required">
                  City is required
                </div>
                <div *ngIf="getFormValidation?.city?.errors?.pattern">
                  Numbers and special characters are not allowed
                </div>
              </div>
            </div>
            <div class="col-3 form-group">
              <label class="form-group required">State</label>
              <input
                type="text"
                class="form-control"
                name="state"
                formControlName="state"
                [ngClass]="{
                  'is-invalid':
                    getFormValidation?.state?.errors &&
                    (submitted || getFormValidation?.state?.touched)
                }"
              />
              <div
                class="invalid-feedback"
                *ngIf="
                  getFormValidation?.state?.errors &&
                  (submitted || getFormValidation?.state?.touched)
                "
              >
                <div *ngIf="getFormValidation?.state?.errors?.required">
                  State is required
                </div>
                <div *ngIf="getFormValidation?.state?.errors?.pattern">
                  Numbers and special characters are not allowed
                </div>
              </div>
            </div>
            <div class="col-3 form-group">
              <label class="form-group required">pincode</label>
              <input
                type="text"
                class="form-control"
                name="pincode"
                formControlName="pincode"
                [ngClass]="{
                  'is-invalid':
                    getFormValidation?.pincode?.errors &&
                    (submitted || getFormValidation?.pincode?.touched)
                }"
              />
              <div
                class="invalid-feedback"
                *ngIf="
                  getFormValidation?.pincode?.errors &&
                  (submitted || getFormValidation?.pincode?.touched)
                "
              >
                <div *ngIf="getFormValidation?.pincode?.errors?.required">
                  Pin code is required
                </div>
              </div>
            </div>
            <div class="col-3 form-group">
              <label class="form-group required">Country Code</label>
              <input
                type="text"
                class="form-control"
                name="country_code"
                formControlName="country_code"
                [ngClass]="{
                  'is-invalid':
                    getFormValidation?.country_code?.errors &&
                    (submitted || getFormValidation?.country_code?.touched)
                }"
              />
              <div
                class="invalid-feedback"
                *ngIf="
                  getFormValidation?.country_code?.errors &&
                  (submitted || getFormValidation?.country_code?.touched)
                "
              >
                <div *ngIf="getFormValidation?.country_code?.errors?.required">
                  Country code is required
                </div>
                <div *ngIf="getFormValidation?.country_code?.errors?.pattern">
                  Only maximum two characters are allowed
                </div>
              </div>
            </div>
          </div>

          <div class="form-check mb-2">
            <input
              type="checkbox"
              class="form-check-input"
              name="acceptform"
              formControlName="acceptform"
              [ngClass]="{ 'is-invalid': submitted && f.acceptform.errors }"
            />
            <label class="form-check-label required" for=""
              >Please agree to our Terms & Conditions</label
            >
          </div>

          <!-- <button  type="button" class="btn prime w-100" data-toggle="modal" data-target="#myModal" >Next</button> -->
          <button
            [disabled]="
              !bankRegisterForm.valid ||
              bankRegisterForm.value.acceptform !== true
            "
            type="button"
            class="btn prime w-100"
            data-toggle="modal"
            data-target="#myModal"
          >
            Next
          </button>
        </div>

        <!-- The Modal -->
        <div class="modal fade" id="myModal">
          <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content">
              <!-- Modal Header -->
              <div class="modal-header">
                <h2 class="modal-title">Our Licenses</h2>
                <button
                  id="closeLicenceModal"
                  type=""
                  class="visibility-hidden btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <!-- Modal body -->
              <div class="modal-body">
                <div class="row">
                  <div
                    class="card col-6 mb-3"
                    style="width: 18rem"
                    *ngFor="let card of cards"
                  >
                    <div class="card-inner">
                      <p class="card_title text-center">
                        {{ card.license_name }} {{ card.license_id }}
                      </p>
                      <div class="card-body text-left">
                        <div>
                          <label class="label">Description:</label>
                          {{ card.license_desc }}
                        </div>
                        <div>
                          <label class="label">Package Price:</label>
                          {{ card.package_price }}
                        </div>
                        <div>
                          <label class="label">Currency:</label>
                          {{ card.acceptable_ccy }}
                        </div>
                        <div>
                          <label class="label">Country:</label>
                          {{ card.country }}
                        </div>
                        <div>
                          <label class="label">Version:</label>
                          {{ card.license_version_name }}
                        </div>
                        <div>
                          <label class="label">Trail Period:</label>
                          {{ card.trail_period }}
                        </div>
                        <div>
                          <label class="label">Validity:</label>
                          {{ card.validity }}
                        </div>
                      </div>
                      <input
                        type="checkbox"
                        multiple
                        class="form-control"
                        formArrayName="license_id"
                        [value]="card.license_id"
                        (change)="onCheckBoxChange($event)"
                      />
                    </div>
                    <!-- <h4 class="text-center">
                      {{ card.license_name }} {{ card.license_id }}
                    </h4> -->
                    <!-- <div class="card-body text-left"> -->
                    <!-- <p>{{ card.license_desc }}</p>
                      Package Price:
                      <h6>{{ card.package_price }}</h6>
                      Currency :
                      <h6>{{ card.acceptable_ccy }}</h6>
                      Country:
                      <h6>{{ card.country }}</h6>
                      Version:
                      <h6>{{ card.license_version_name }}</h6>
                      <b>Trail Period: </b>
                      <h6>{{ card.trail_period }}</h6>
                      <b> Validity: </b>
                      <h6>{{ card.validity }}</h6> -->

                    <!-- </div> -->
                  </div>
                </div>
                <div class="text-right">
                  <button
                    [disabled]="bankRegisterForm.value.license_id.length === 0"
                    type="submit"
                    class="mr-2"
                    nbButton
                    status="success"
                  >
                    submit
                  </button>
                  <button
                    nbButton
                    hero
                    status="info"
                    type="button"
                    (click)="back()"
                  >
                    Back
                  </button>
                </div>

                <!-- <button type="submit" class="btn btn-primary" > submit</button> -->
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
