import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LayoutComponent } from './layout.component';
import { BankregisterComponent } from './bankregister/bankregister.component';
import { BranchregisterComponent } from './branchregister/branchregister.component';
import { BranchviewComponent } from './branchview/branchview.component';
import { BranchEditComponent } from './branch-edit/branch-edit.component';
import { InterestsComponent } from './interests/interests.component';
import { InterestsFormComponent } from './interests-form/interests-form.component';
import { InterestsEditComponent } from './interests-edit/interests-edit.component';
import { FeeComponent } from './fee/fee.component';
import { FeeFormComponent } from './fee-form/fee-form.component';
import { FeeEditComponent } from './fee-edit/fee-edit.component';

const routes: Routes = [{
  path: '',
  component: LayoutComponent,
  children: [
    {
      path: 'bankregister',
      component: BankregisterComponent,
    },
    {
      path: 'bankedit/:id',
      component: BranchEditComponent,
    },
    {
      path: 'branchregister',
      component: BranchregisterComponent,
    },
    {
      path: 'branchview',
      component: BranchviewComponent,
    },
    {
      path: 'Interests',
      component: InterestsComponent,
    },
    {
      path: 'Interestsform',
      component: InterestsFormComponent,
    },
    {
      path: 'Interestsedit',
      component: InterestsEditComponent,
    },
    
    {
      path: 'Fee',
      component: FeeComponent,
    },
    {
      path: 'Feeform',
      component: FeeFormComponent,
    },
    {
      path: 'Feeedit',
      component: FeeEditComponent,
    },
  ],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LayoutRoutingModule {
}
