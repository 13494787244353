import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/operators';
import { HelpersComponent } from '../../helpers/helpers.component';

@Injectable({
  providedIn: 'root'
})
export class FeedetailsService {
  feeRegisterMethod(branchpostdata: any) {
    throw new Error('Method not implemented.');
  }
  
  private headers = new HttpHeaders();

  constructor(private _http: HttpClient, private _helperComponent: HelpersComponent) { }
  
  private _feeserviceURL = "";

  feeMethod(formData) {
    this.headers.append('Content-Type', 'multipart/form-data');
    this.headers.append('Accept', 'application/json');
    return this._http.post<any>(this._feeserviceURL + '/api/v1/create_fee_type ', formData).pipe(retry(1),
      catchError(this._helperComponent.handleError)
    );
  }

  // iduival fee data get method
  feeGet(id) {
    return this._http.get<any>(this._feeserviceURL + '/api/v1/get_fee_type/{fee_type_code}/' + id).pipe(retry(1),
      catchError(this._helperComponent.handleError)
    );

  }

  // all fee data get method
  allFeeGet() {

    return this._http.get<any>(this._feeserviceURL + '/api/v1/getall_fee_type_detail')


  }

  // fee data update method
  feeUpdate(id, formdata) {
    return this._http.put<any>(this._feeserviceURL + '/api/v1/update_fee_type_detail   /{fee_type_code}/' + id, formdata, {
      headers: this.headers
    }).pipe(retry(1),
      catchError(this._helperComponent.handleError)
    );
  }

  // fee details create method
  feeDetailsCreate(formdata) {
    return this._http.post<any>(this._feeserviceURL + '/api/v1/create_fee_master_maintenance /', formdata, {
      headers: this.headers
    }).pipe(retry(1),
      catchError(this._helperComponent.handleError)
    );
  }

  // feedetails get method
  feeDetailsGet(id) {
    return this._http.get<any>(this._feeserviceURL + '/api/v1/get_fee_master_maintenance/{fee_code} ,/' + id, {
      headers: this.headers
    }).pipe(retry(1),
      catchError(this._helperComponent.handleError)
    );
  }

  // all fee details get method
  allfeeDetailsGet() {
    return this._http.get<any>(this._feeserviceURL + '/api/v1/getall_fee_master_maintenance_detail/', {
      headers: this.headers
    }).pipe(retry(1),
      catchError(this._helperComponent.handleError)
    );
  }

  // fee details update method
  feeDetailsUpdate(id, formdata) {
    return this._http.put<any>(this._feeserviceURL + '/api/v1/update_fee_master_maintenance_detail   /{fee_code}/' + id, formdata, {
      headers: this.headers
    }).pipe(retry(1),
      catchError(this._helperComponent.handleError)
    );
  }


}
