import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbCarouselConfig } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { first } from "rxjs/operators";
import { AlertService } from "../../../../helpers/alert.service";
import { HelpersComponent } from "../../../../helpers/helpers.component";
import { ApiService } from "../../../../helpers/notification.service";
import { AuthenticationService } from "../authencation.service";
import { Subscription } from "rxjs/internal/Subscription";
import { Observable } from "rxjs/internal/Observable";
import { Pipe, PipeTransform } from "@angular/core";

@Component({
  selector: "ngx-epinpage",
  templateUrl: "./epinpage.component.html",
  styleUrls: ["./epinpage.component.scss"],
})
export class EpinpageComponent implements OnInit {
  epinForm: FormGroup;
  epinData: any = {};
  loading = false;
  submitted = false;
  returnUrl: string;
  errorCardEpin = false;
  epinError = "";

  getStorage: any;

  timeLeft: number = 60;
  interval;

  value: string;

  isLogin = false;

  roleAs: string;

  @ViewChild("closeModal") closeModal: ElementRef;

  constructor(
    private ref: ChangeDetectorRef,
    private apiService: ApiService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private _helperComponent: HelpersComponent,
    private spinner: NgxSpinnerService,
    _config: NgbCarouselConfig,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    console.log("epin");

    this.epinForm = this.fb.group({
      epin: new FormControl("", [
        Validators.required,
        Validators.pattern("^[0-9]*$"),
      ]),
      transaction_code: "USR_GID_USR_308",
      UI_page_id: "1234a",
    });
  }

  get f() {
    return this.epinForm.controls;
  }

  onEpinSubmit() {
    this.submitted = true;
    this.spinner.show();

    if (this.epinForm.invalid) {
      this.spinner.hide();
      return;
    }
    let epinValue = this.epinForm.value;
    console.log(epinValue);

    Object.entries(epinValue).forEach(([key, values]) => {
      this.epinData[key] = values;
    });
    console.log(this.epinData);

    return this.authenticationService
      .postEpin(this.epinData)
      .subscribe(
        (res) => {
          console.log(res);
          let userType = res.user_role_name;

          // console.log("Here = ", JSON.stringify(res["user_role_name"]));
          // if (JSON.stringify(res["user_role_name"]) === '"Super_Admin"') {
          //   console.log("Here Here = ", JSON.stringify(res["user_role_name"]));
          //   localStorage.setItem("bank_id", res["bank_id"]);
          // } else {
          //   localStorage.setItem("bank_id", JSON.stringify(res["bank_id"]));
          // }
          localStorage.setItem("bank_id", JSON.stringify(res["bank_id"]));

          // Handling Mutliple Branch Groups for Manager
          if (userType === "Regional Manager" || userType === "Teller") {
            localStorage.setItem(
              "branch_group_id",
              JSON.stringify(res["branch_group_id"])
            );
          }
          // localStorage.setItem(
          //   "branch_group_id",
          //   JSON.parse(res["branch_group_id"][0])
          // );

          localStorage.setItem("branch_id", JSON.stringify(res["branch_id"]));
          localStorage.setItem("is_checker", JSON.stringify(res["is_checker"]));
          localStorage.setItem("is_maker", JSON.stringify(res["is_maker"]));
          localStorage.setItem(
            "transaction_code_and_UI_page_id",
            JSON.stringify(res["transaction_code_and_UI_page_id"])
          );
          localStorage.setItem("message", JSON.stringify(res["message"]));
          localStorage.setItem(
            "user_role_name",
            JSON.stringify(res["user_role_name"])
          );
          localStorage.setItem("user_id", res["user_id"]);

          this.spinner.hide();
          // let userType = res.user_role_name;
          let navigateTo = "";

          if (userType === "Admin" || userType === "Super_Admin") {
            /// role  authorised so redirect to branch selection page
            navigateTo = "/pages";
          } else if (userType === "Teller") {
            navigateTo = "/branchselection";
          } else if (userType === "Regional Manager") {
            navigateTo = "/branchselection";
          }
          this.router.navigate([navigateTo]);
        },

        (_error) => {
          this.epinError = _error;
          if (_error === "epin is incorrect") {
            this.errorCardEpin = true;
          } else {
            this.errorCardEpin = false;
          }
        }
      )
      .add(() => {
        this.spinner.hide();
      });
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.timeLeft = 60;
      }
    }, 1000);
  }

  onReset() {
    this.submitted = false;
    this.epinForm.reset();
  }

  login(val: string) {
    this.authenticationService.Login(val).subscribe((res) => {
      if (res.success) {
        this.closeModal.nativeElement.click();
      }
    });
  }

  // goToDashBoard() {
  //   let role = this.authenticationService.getRole();
  //   if (role === 'Admin' || 'Super_Admin')
  //     this.router.navigate(['/pages']);
  //   if (role !== 'Admin' || 'Super_Admin')
  //     this.router.navigate(['/pages']);

  // }
}

// goToDashBoard() {
//   let role = this.authenticationService.getRole();
//   if (role === 'Admin' || 'Super_Admin'){
//     this.router.navigate(['/pages']);
//    } else {
//     this.router.navigate(['/branchselection']);
//   }

// }
