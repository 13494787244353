import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbCarouselConfig } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { first } from "rxjs/operators";
import { AlertService } from "../../../../helpers/alert.service";
import { HelpersComponent } from "../../../../helpers/helpers.component";
import { ApiService } from "../../../../helpers/notification.service";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { AuthenticationService } from "../authencation.service";
import { BranchService } from "../../../../pages/branchmaintenance/branch.service";
import { EntityParameterService } from "../../../../pages/entity-parameter/entity-parameter.service";
@Component({
  selector: "ngx-branchselection",
  templateUrl: "./branchselection.component.html",
  styleUrls: ["./branchselection.component.scss"],
})
export class BranchselectionComponent implements OnInit {
  branch = [];
  showStorage: "";
  getStorage: "";
  brnSelectionForm: FormGroup;
  branchDetails = [];
  loading = false;
  submitted = false;
  returnUrl: string;
  ErrorCard = false;
  branch_id = "";
  branch_group_id = " ";
  branchForm = [];
  grpmaster = [];
  @ViewChild("closeModal") closeModal: ElementRef;
  isLoggedIn = false;
  selectedBranch: any = "";
  selectedBranchGrp: any;

  branchDropdownData = [];

  constructor(
    private apiService: ApiService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private _helperComponent: HelpersComponent,
    private spinner: NgxSpinnerService,
    config: NgbCarouselConfig,
    private authenticationService: AuthenticationService,
    private branchService: BranchService,
    private entityParameterService: EntityParameterService
  ) {
    this.isLoggedIn = false;
    config.interval = 8000;
    config.wrap = true;
    config.keyboard = false;
    config.pauseOnHover = true;
  }

  ngOnInit(): void {
    this.brnSelectionForm = this.fb.group({
      branch_group_id: new FormControl(""),
      branch_id: new FormControl(""),
      //branch_group_id: this.fb.array([]),
      //branch_id: this.fb.array([]),
      transaction_code: "USR_GID_USR_308",
      UI_page_id: "1234a",
    });

    this.showStorage = JSON.parse(localStorage.getItem("branch_group_id[0]"));
    this.getStorage = JSON.parse(localStorage.getItem("branch_id[0]"));
    console.log("local storage", this.showStorage, this.getStorage);

    // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/pages'
    // this.authenticationService.logout();

    // this.getData(this.route.snapshot.paramMap.get('branch_id'));
    // this.getMater(this.route.snapshot.paramMap.get('branch_group_id'));

    this.getBranchDetails();
    this.getBranchGroupDetails();

    let brnData = {
      transaction_code: "string",
      UI_page_id: "BHMAST003",
    };
    // this.branchService.getAllBranch(brnData).subscribe(data=>{
    //   this.branch = data;
    //   console.log(this.branch);
    // })
  }

  getBranchDetails() {
    let branchData = {
      transaction_code: "string",
      UI_page_id: "BKMAST002",
    };

    let branch_id = JSON.parse(localStorage.getItem("branch_id"));

    // if (branch_id.length > 1) {
    //   branch_id = [branch_id[0]];
    // }

    console.log("Selected Branch ID =", branch_id);

    Object.keys(branch_id).length;

    for (let index = 0; index < branch_id.length; index++) {
      const element = branch_id[index];

      this.branchService.getBranch(element, branchData).subscribe(
        (data) => {
          this.branchDropdownData.push(data);
          console.log("Dropdown Branch Data =", this.branchForm);
          // this.selectedBranch = branch_id;
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  // getBranchDetails() {
  //   let branchData = {
  //     transaction_code: "string",
  //     UI_page_id: "BKMAST002",
  //   };

  //   let branch_id = JSON.parse(localStorage.getItem("branch_id"));

  //   if (branch_id.length > 1) {
  //     branch_id = [branch_id[0]];
  //   }

  //   console.log("Selected Branch ID =", branch_id);

  //   Object.keys(branch_id).length;
  //   this.branchService.getBranch(branch_id, branchData).subscribe(
  //     (data) => {
  //       this.branchForm.push(data);
  //       console.log("Dropdown Branch Data =", this.branchForm);
  //       this.selectedBranch = branch_id;
  //     },
  //     (error) => {
  //       console.log(error);
  //     }
  //   );
  // }

  getBranchGroupDetails() {
    console.log("Fetching branch grp details...");
    let grpMaster = {
      transaction_code: "string",
      UI_page_id: "BHGRP003",
    };

    let grpIds = JSON.parse(localStorage.getItem("branch_group_id"));

    if (grpIds.length > 1) {
      console.log("Multiple Group IDs Found", grpIds.length);

      for (let index = 0; index < grpIds.length; index++) {
        const element = grpIds[index];
        console.log("E = ", element);

        this.branchService
          .getBranchGroupDetails(element, grpMaster)
          .subscribe((data) => {
            this.grpmaster.push(data);
          });

        this.branchService
          .getbranchgroup(element, grpMaster)
          .subscribe((data) => {
            console.log("Branch Group Data = ", element, data);
          });
      }

      console.log("Branch Group Data = ", this.grpmaster);
      this.selectedBranchGrp = grpIds[0];
      console.log("Selected Branch Group ID =", this.selectedBranchGrp);
    } else {
      let branch_grp_id = JSON.parse(
        localStorage.getItem("branch_group_id")
      )[0];

      console.log("Branch Group ID = ", branch_grp_id);

      this.branchService
        .getBranchGroupDetails(branch_grp_id, grpMaster)
        .subscribe((data) => {
          this.grpmaster.push(data);
          console.log("Branch Group Data =", this.grpmaster);
          this.selectedBranchGrp = branch_grp_id;
          console.log("Selected Branch Group ID =", this.selectedBranchGrp);
        });
    }

    // let branch_grp_id = JSON.parse(localStorage.getItem('branch_group_id'));

    // this.branchService.getBranchGroupDetails(branch_grp_id, grpMaster).subscribe(data => {
    //   this.grpmaster.push(data);
    //   console.log("Branch Group Data =", this.grpmaster);
    //   this.selectedBranchGrp = branch_grp_id;
    //   console.log("Selected Branch Group ID =", this.selectedBranchGrp);
    // })
  }

  get f() {
    return this.brnSelectionForm.controls;
  }

  onSelectionSubmit() {
    this.submitted = true;
    this.spinner.show();

    if (this.brnSelectionForm.invalid) {
      return;
    }
    let brnValue = this.brnSelectionForm.value;
    console.log("Selected values =", brnValue);

    Object.entries(brnValue).forEach(([key, values]) => {
      this.branchDetails[key] = values;
    });

    // brnValue["branch_id"] = this.branchDetails["branch_id"][0];
    brnValue["branch_id"] = this.branchDetails["branch_id"];
    brnValue["branch_group_id"] = this.branchDetails["branch_group_id"];
    // brnValue["branch_group_id"] = this.branchDetails["branch_group_id"][0];

    console.log("Branch Derails forms values = ", brnValue);

    return this.authenticationService
      .brnSelection(brnValue)
      .subscribe(
        (res) => {
          console.log(res);
          this.spinner.hide();
          if (localStorage.getItem("Access-Token") !== null) {
            /// role not authorised so redirect to home page
            // this.router.navigate(['/pages/teller']);
            console.log(
              "===",
              localStorage.getItem("user_role_name") === '"Regional Manager"'
            );
            if (
              localStorage.getItem("user_role_name") === '"Regional Manager"'
            ) {
              this.router.navigate(["/pages/manager/dashboard"]);
            } else {
              this.router.navigate(["/pages/teller/tellerdash"]);
            }
          }
          this.spinner.hide();
          //this.router.navigate(['/pages']);
        },
        (error) => {
          // if (error.status == 401) {
          this.ErrorCard = true;
          // }
        }
      )
      .add(() => {
        this.spinner.hide();
      });
  }

  selectBranchGroup(grpName) {
    console.log("Group Name = ", grpName);
  }

  getData(branch_id): void {
    let branchData = {
      transaction_code: "string",
      UI_page_id: "BKMAST002",
    };

    branch_id = JSON.parse(localStorage.getItem("branch_id"));

    if (branch_id.length > 1) {
      branch_id = [branch_id[0]];
    }

    console.log("branch123", branch_id);
    // console.log(typeof (branch_id));
    Object.keys(branch_id).length;
    this.branchService.getBranch(branch_id, branchData).subscribe(
      (data) => {
        // this.currentTutorial = data;
        this.branchForm.push(data);
        console.log("Dropdown Branch Data =", this.branchForm);
      },
      (error) => {
        console.log(error);
      }
    );
    // add(() => {
    //   this.spinner.hide();
    // });

    // let brnData = {
    //   transaction_code: "string",
    //   UI_page_id: 'BHMAST003',
    // }

    // this.branchService.getAllBranch(brnData).subscribe(data=>{
    //   this.branch = data;
    //   this.branchForm.push(data);
    //   console.log(this.branch);
    // })
  }

  selectBranchGroupName(prod) {
    console.log(prod);
    let grpMaster = {
      transaction_code: "string",
      UI_page_id: "BHGRP003",
    };

    this.branchService.getAllBranchgroupmaster(grpMaster).subscribe((data) => {
      this.grpmaster = data;
      console.log(this.grpmaster);
    });
  }

  getMater(branch_group_id): void {
    let grpData = {
      transaction_code: "string",
      UI_page_id: "BHGRP002",
    };
    branch_group_id = JSON.parse(localStorage.getItem("branch_group_id"));
    this.branchService.getbranchgroup(branch_group_id, grpData).subscribe(
      (data) => {
        // this.currentTutorial = data;
        // this.branchForm.push(data);
        console.log("Branch Group Dwtails = ", data);
      },
      (error) => {
        console.log(error);
      }
    );
    // add(() => {
    //   this.spinner.hide();
    // });
  }

  loginAsUser() {
    this.isLoggedIn = true;
  }

  loginAsAdmin() {
    console.log("Login As aDmin");
    this.isLoggedIn = true;
    this.closeModal.nativeElement.click();
  }

  logout() {
    this.isLoggedIn = false;
  }

  onReset() {
    this.submitted = false;
    this.brnSelectionForm.reset();
  }
}
