<div id="wrapper">
    <div id="dialog">
      <h3>Please enter the 4-digit verification code we sent via SMS:</h3>
      <span>(we want to make sure it's you before we contact our movers)</span>
      <div id="form">
        <input type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" />
        <input type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" />
        <input type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" />
        <input type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" />
        <a routerLink="/pages/iot-dashboard"><button class="btn btn-primary btn-embossed">Verify</button></a>
      </div>
      
      <div>
        Didn't receive the code?<br />
        <a href="#">Send code again</a><br/>       
      </div>       
    </div>
  </div>