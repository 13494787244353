import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { HelpersComponent } from '../../helpers/helpers.component';
@Injectable({
  providedIn: 'root'
})
export class AdminserviceService {
  private headers = new HttpHeaders();
  [x: string]: any;
  postdata: any;

  constructor(private _http: HttpClient, private _helperComponent: HelpersComponent) { }

  private _dfAdminService = "http://loadbalancer.danfishel.com/Danfisheladminservice";

  private handleError(errorReponse: HttpErrorResponse) {
    console.log('Client side error ', errorReponse.error.message)
    return throwError(errorReponse.error.message);
  }

  get_all_license(guest: boolean): Observable<any> { 
    if(guest) {
      const headers = new HttpHeaders().set('Skip-Interceptor', 'true');
      return this._http.get<any>(this._dfAdminService + '/api/v1/licdetail',{ headers: headers});
    } else {
      return this._http.get<any>(this._dfAdminService + '/api/v1/licdetail');
    }
  }
}
