<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<nav>
  <img src="assets/images/Danfishel-logo.png" class="circle2" width="150" />
</nav>

<section class="loginContainer">
  <div class="row">
    <div class="col-md-8">
      <ngb-carousel [interval]="3000">
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <img
              src="assets/images/camera1.jpg"
              class="d-block w-100"
              alt="first slide"
            />
          </div>
          <div class="carousel-caption">
            <h3>First Slide</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <img
              src="assets/images/camera2.jpg"
              class="d-block w-100"
              alt="second slide"
            />
          </div>
          <div class="carousel-caption">
            <h3>Second Slide</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </div>
        </ng-template>
      </ngb-carousel>
    </div>

    <div class="col-md-4 login-form">
      <div>
        <h3>Sign-In</h3>
        <hr class="signborder" />
      </div>
      <form
        class="login-form"
        [formGroup]="epinForm"
        (ngSubmit)="onEpinSubmit()"
      >
        <div class="form-row">
          <div class="form-group col-12">
            <label for="username">Epin</label>
            <input
              type="password"
              formControlName="epin"
              name="epin"
              class="form-control"
              id="epin"
              placeholder="Enter your Epin"
              [ngClass]="{ 'is-invalid': submitted && f.epin.errors }"
            />
            <!-- <div *ngIf="submitted && f.epin.errors" class="invalid-feedback">
                            <div *ngIf="f.epin.errors.required">Epin is required</div>
                        </div> -->
            <div
              class="invalid-feedback"
              *ngIf="f?.epin?.errors && (submitted || f?.epin?.touched)"
            >
              <div *ngIf="f?.epin?.errors?.required">Epin is required!</div>
              <div *ngIf="f?.epin?.errors?.pattern">
                Epin should be a number only!
              </div>
            </div>
            <div class="form-row mt-2 ml-1" *ngIf="errorCardEpin">
              <span style="color: red">Epin is invalid!</span>
            </div>
          </div>
        </div>

        <!-- <div>
                        <p>or</p>
                        <button type="button" class="btn btn-link">Generate new epin</button>
                </div> -->

        <div class="form-row mt-3">
          <button
            [disabled]="loading"
            class="btn btn-block btn-submit"
            (click)="onEpinSubmit()"
          >
            <span
              *ngIf="loading"
              class="spinner-border spinner-border-sm mr-1"
            ></span>
            <!-- <div *ngIf="getStorage; else elseBlock">
                            <a routerLink="/branchselection" routerLinkActive="active"></a></div>
                        <ng-template #elseBlock><a routerLink="/pages" routerLinkActive="active"></a></ng-template> -->

            Login
          </button>
        </div>

        <div class="form-row mt-3">
          <!-- <div class="register col-6">
                        <a routerLink="/register" routerLinkActive="active">
                            <i class="fa fa-user">
                                sign-up</i></a>
                    </div> -->

          <div class="form-group col-6">
            <a class="text-link" (click)="startTimer()">Generate New Epin</a>
          </div>

          <div class="form-group col-6">
            <div class="form-check form-check-inline">
              <div class="time">{{ timeLeft }}secondleft</div>
              <!-- <div class="time">{{minutes}}</div>  <div class="time">{{seconds}}</div> -->
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
