import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { BranchService } from '../../../pages/branchmaintenance/branch.service';
import { BankService } from '../../../pages/bank-maintenance/bank.service';
import { AuthenticationService } from '../../../auth/components/login/authencation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  show= [];
  bankName = " ";
  countryName = " ";
  bankId: any;
  country: any;
  branch=[];
  bank_id = "";
  userType: any;
  showCheckout = false;
  showCountryName = true;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    // {
    //   value: 'cosmic',
    //   name: 'Cosmic',
    // },
    // {
    //   value: 'corporate',
    //   name: 'Corporate',
    // },
  ];

  currentTheme = 'default';

  userMenu = [{ title: 'Profile' }, { title: 'Log out' }];
  route: any;

    constructor(private spinner: NgxSpinnerService, private Service: NbSidebarService,
      private menuService: NbMenuService,
      private themeService: NbThemeService,
      private userService: UserData,
      private layoutService: LayoutService,
      private breakpointService: NbMediaBreakpointsService,
      private branchService: BranchService,
      private bankService:BankService,
      private authService: AuthenticationService,
      private router: Router
      ) {
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

      let code = {
        transaction_code: "string",
        UI_page_id: 'BKMAST002'
      }
    
      // (localStorage.getItem('bank_id') == 'show')
      this.userType = JSON.parse(localStorage.getItem('user_role_name'));
      console.log("User Type = ",this.userType);
      console.log("User Type Length= ",this.userType.length);
      if (this.userType === 'Super_Admin') {
        this.showCountryName = false;
      }
      if (this.userType === 'Super_Admin' || this.userType === 'Admin') {
        this.showCheckout = false;
      } else {
        this.showCheckout = true;
      }
      console.log("Show Checkout = ", this.showCheckout);

      this.bankId = JSON.parse(localStorage.getItem('bank_id'));
      console.log("Bank ID = ", this.bankId);

      this.bankService.getbankmastermaintenance(this.bankId, code).subscribe(data=>{
        this.bankName = data.bank_name;
      })
      // this.bankService.get_bank_name(this.bankId, code).subscribe(data=>{
      //   this.bankName = data;
      //   console.log("---Bank Name---",this.bankName);
      // })
      // this.bankName = this.bankId;
      if(this.bankName === null){
        this.bankName = "NA";
      }
      console.log(this.bankName);

      let brnData = {
        transaction_code: "string",
        UI_page_id: 'BHMAST003',
      }

      this.branchService.getAllBranch(brnData).subscribe(data=>{
        this.country = data[0].country
        this.countryName = this.country
        console.log(this.countryName); 
      })

      
  

  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.Service.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    return false;
  }

  
  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
  
  
  checkoutUser(formData) {
    formData = { "transaction_code" : "USR_UCI_USR_425", "UI_page_id" : "USCHO002" }
    this.authService.checkouUser(formData).subscribe(data=>{
      console.log("Checkout Done"); 
      this.router.navigateByUrl('/branchselection');
    })
  }
}
