import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { HelpersComponent } from '../../helpers/helpers.component';

@Injectable({
  providedIn: 'root'
})
export class InterestformService {
  push(res: any) {
    throw new Error('Method not implemented.');
  }

  private headers = new HttpHeaders();


  constructor(private _http: HttpClient, private _helperComponent: HelpersComponent) { }
  private _interestserviceURL = "";

  interestMethod(formData) {
    this.headers.append('Content-Type', 'multipart/form-data');
    this.headers.append('Accept', 'application/json');
    return this._http.post<any>(this._interestserviceURL + '/api/v1/create_interest_rate_type', formData).pipe(retry(1),
      catchError(this._helperComponent.handleError)
    );
  }

  interestGet(id) {
    return this._http.get<any>(this._interestserviceURL + '/api/v1/get_interest_rate_type/{rate_type_code} , bank/' + id).pipe(retry(1),
      catchError(this._helperComponent.handleError)
    );

  }

  allInterestGet() {

    return this._http.get<any>(this._interestserviceURL + '/api/v1/getall_interest_rate_type_detail')


  }
  interestUpdate(id, formdata) {
    return this._http.put<any>(this._interestserviceURL + '/api/v1/update_interest_rate_type_detail /{rate_type_code}' + id, formdata, {
      headers: this.headers
    }).pipe(retry(1),
      catchError(this._helperComponent.handleError)
    );
  }

  interestCreate(formdata) {
    return this._http.post<any>(this._interestserviceURL + '/api/v1/create_interest_master/', formdata, {
      headers: this.headers
    }).pipe(retry(1),
      catchError(this._helperComponent.handleError)
    );
  }

  interestDetailsGet(id) {
    return this._http.get<any>(this._interestserviceURL + '/api/v1/get_interest_master/{interest_type_code}/' + id, {
      headers: this.headers
    }).pipe(retry(1),
      catchError(this._helperComponent.handleError)
    );
  }

  allinterestDetailsGet() {
    return this._http.get<any>(this._interestserviceURL + '/api/v1/getall_interest_master_detail /', {
      headers: this.headers
    }).pipe(retry(1),
      catchError(this._helperComponent.handleError)
    );
  }
  interestDetailsUpdate(id, formdata) {
    return this._http.put<any>(this._interestserviceURL + '/api/v1/update_interest_master_detail  /{interest_type_code}/' + id, formdata, {
      headers: this.headers
    }).pipe(retry(1),
      catchError(this._helperComponent.handleError)
    );
  }



}
function retry(arg0: number): import("rxjs").OperatorFunction<any, unknown> {
  throw new Error('Function not implemented.');
}

