import { Injectable } from '@angular/core';
import { NbComponentStatus, NbToastrService } from '@nebular/theme';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(/* private toastr: ToastrService, */ private toastrService: NbToastrService) { }
  showSuccess(status, message: string) {
    this.toastrService.show('Success', `${message}`, { status });
  }
  showError(status, message: string) {
    this.toastrService.show('Error', `${message}`, { status });
  }
  // showInfo(msg, hading) {
  //   this.toastr.info(hading, msg, {
  //     timeOut: 5000,
  //     positionClass: 'toast-top-right',
  //   });
  // }
  // showWarning(msg, hading) {
  //   this.toastr.warning(hading, msg, {
  //     timeOut: 5000,
  //     positionClass: 'toast-top-right',
  //   });
  // }
}
