import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-fee',
  templateUrl: './fee.component.html',
  styleUrls: ['./fee.component.scss']
})
export class FeeComponent implements OnInit {
  router: any;

  constructor() { }

  ngOnInit(): void {
  }
  viewback(){
    this.router.navigate(['pages/usermaintenance/userform'])
  }
  
}
