<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<nav class="navbar navbar-light justify-content-between">
  <img src="assets/images/Danfishel-logo.png" class="circle2" width="150" />
  <div class="register">
    <a routerLink="/register" routerLinkActive="active">
      <i class="fa fa-user"> sign-up</i></a
    >
  </div>
</nav>

<section class="loginContainer">
  <div class="row">
    <div class="col-md-8">
      <ngb-carousel [interval]="3000">
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <img
              src="assets/images/camera1.jpg"
              class="d-block w-100"
              alt="first slide"
            />
          </div>
          <div class="carousel-caption">
            <h3>First Slide</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <img
              src="assets/images/camera2.jpg"
              class="d-block w-100"
              alt="second slide"
            />
          </div>
          <div class="carousel-caption">
            <h3>Second Slide</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </div>
        </ng-template>
      </ngb-carousel>
    </div>

    <div class="col-md-4 login-form">
      <div>
        <h3>Branch Selection</h3>
        <hr class="signborder" />
      </div>
      <form
        class="login-form"
        [formGroup]="brnSelectionForm"
        (ngSubmit)="onSelectionSubmit()"
      >
        <div class="form-row">
          <div class="form-group col-12">
            <label for="branch_group_id">Branch Group Name</label>
            <select
              type="text"
              formControlName="branch_group_id"
              name="branch_group_id"
              class="form-control"
              [ngClass]="{
                'is-invalid': submitted && f.branch_group_id.errors
              }"
              (change)="selectBranchGroup($event.target.value)"
              [(ngModel)]="selectedBranchGrp"
            >
              <option value="" selected disabled>Select</option>
              <!-- <option value="grpmaster.branch_group_id">
                {{ grpmaster.brn_group_name }}
              </option> -->
              <option
                *ngFor="let grpName of grpmaster"
                [value]="grpName.branch_group_id"
              >
                {{ grpName.brn_group_name }}
              </option>
            </select>
            <!-- <input type="text" formControlName="branch_group_id" name="branch_group_id" class="form-control"
                            id="branch_group_id" [ngClass]="{ 'is-invalid': submitted && f.branch_group_id.errors }">
                        <div>{{getStorage}}</div> -->
            <!-- <div *ngFor="let prod of branchForm" [value]="prod.branch_group_id">{{prod.brn_group_name}}</div> -->
            <div
              *ngIf="submitted && f.branch_group_id.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.branch_group_id.errors.required">
                Branch Group Name is required
              </div>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-12">
            <label for="branch_id">Branch Name </label>

            <select
              type="text"
              formControlName="branch_id"
              name="branch_id"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.branch_id.errors }"
              (change)="selectBranchGroupName($event.target.value)"
              [(ngModel)]="selectedBranch"
              placeholder="Select"
            >
              <option value="" selected disabled>Select</option>
              <option
                *ngFor="let prod of branchDropdownData"
                [value]="prod.branch_id"
              >
                {{ prod.branch_name }}
              </option>
              <!-- <option *ngFor="let prod of branchForm" [value]="prod.branch_id">
                {{ prod.branch_name }}
              </option> -->
            </select>
            <div
              *ngIf="submitted && f.branch_id.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.branch_id.errors.required">
                Branch Name is required
              </div>
            </div>
          </div>
        </div>

        <div class="form-row mt-3" *ngIf="ErrorCard">
          <span style="color: red"
            >There was an error trying to authenticate user</span
          >
        </div>
        <div class="form-row mt-3">
          <button [disabled]="loading" class="btn btn-block btn-submit">
            <span
              *ngIf="loading"
              class="spinner-border spinner-border-sm mr-1"
            ></span>
            Check-in
          </button>
        </div>
      </form>
    </div>
  </div>
</section>

<!-- <nb-card>
    
    <nb-card-header  class="text-center">
      <h1>BRANCH SELECTION FORM </h1>
    </nb-card-header>
   
  <form  >         

    
         <div class="row mx-5" >
            <div class="col-6">   
                <label class=" required label">Branch Group </label>
              <select type="text" class="form-control" >
               <!-- name="" formControlName="" [ngClass]="{ 'is-invalid': submitted && f.limit_basis.errors }"  -->
<!-- <option value="select">select</option>
                <option value="daily"></option>
                <option value="monthly">MONTHLY</option>
                <option value="quartely">QUARTERLY	</option>
                <option value="halfyearly">HALF-YEARLY</option>
                <option value="yearly">YEARLY</option>
                </select> -->
<!-- <div *ngIf="submitted && f.limit_basis.errors" class="invalid-feedback">
                <div *ngIf="f.limit_basis.errors.required">Limit Basis is required</div>
                </div>  -->
<!-- </div>
         <div class="col-6">   
            <label class=" required label">Branch Name </label>
          <select type="text" class="form-control" > -->
<!-- name="" formControlName="" [ngClass]="{ 'is-invalid': submitted && f.limit_basis.errors }" > -->
<!-- <option value="select">select</option>
            <option value="daily">Mylapore</option>
            <option value="monthly">Adyar</option>
            <option value="quartely">Ennore	</option>
            <option value="halfyearly">Tambaram</option>
            <option value="yearly">Porur</option>
            </select> -->
<!-- <div *ngIf="submitted && f.limit_basis.errors" class="invalid-feedback">
            <div *ngIf="f.limit_basis.errors.required">Limit Basis is required</div>
            </div>  -->
<!-- </div>
         </div>
         
       <br>
       <br>
       <br>
      

         
      <div class="text-center">
        <button nbButton hero status="primary" style="margin-right: 10px;">Check-in</button>
       
      </div>
      
  </form>
      
</nb-card>
       -->
