import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from '../../../auth/components/login/authencation.service';
import { HelpersComponent } from '../../../helpers/helpers.component';
import { FeedetailsService } from '../feedetails.service';

@Component({
  selector: 'ngx-fee-form',
  templateUrl: './fee-form.component.html',
  styleUrls: ['./fee-form.component.scss']
})
export class FeeFormComponent implements OnInit {
  feename: any;
  feeMethod: any;
  feeDetailsForm: any;
  feeSubmit: boolean;
  feeRegisterForm: any;
  feepostextradata: any;
  submitted: boolean;
  NextBtn: boolean;
  feepostdata: any;

  constructor(
    private spinner: NgxSpinnerService,
    private _helperComponent: HelpersComponent,
    private formBuilder: FormBuilder,
    private _feeservice: FeedetailsService,
    private router: Router,
    config: NgbCarouselConfig,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
  }

  getfeevalue() {
    this.spinner.show();
    return this._feeservice
      .allFeeGet()
      .subscribe(
        (res) => {
          console.log(res, "321");
          this.feename.push(res);
        },
        (err) => {
          this._helperComponent.showToast("danger", err);
        }
      )
      .add(() => {
        this.spinner.hide();
      });
  }
  get f() {
    return this.feeMethod.controls;
  }
  get bd() {
    return this.feeDetailsForm.controls;
  }
  onReset() {
    this.feeMethod.reset();
  }

  onFeeSubmit() {
    this.feeSubmit = true;

    // stop here if form is invalid
    if (this.feeDetailsForm.invalid) {
      return;
    }

    let formData = new FormData();
    let _Formdata = this.feeRegisterForm.value;
    this.spinner.show();
    // Form data
    Object.entries(_Formdata).forEach(([key, values]) => {
      this.feepostextradata[key] = values;
    });

    this._feeservice
      .feeUpdate(this.feeid, this.feepostextradata)
      .subscribe(
        (res) => {
          console.log(res + "done");
          this.spinner.hide();
          // this.router.navigate(["/pages/layout/branchview"]);
        },
        (err) => {
          this.spinner.hide();
          this._helperComponent.showToast("danger", err);
        }
      )
      .add(() => {
        this.spinner.hide();
      });

  }
  feeid(feeid: any, feepostextradata: any) {
    throw new Error('Method not implemented.');
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid

    if (this.feeRegisterForm.invalid) {
      return;
    }
    this.spinner.show();
    let formData = new FormData();
    let _Formdata = this.feeRegisterForm.value;

    // Form data

    Object.entries(_Formdata).forEach(([key, values]) => {
      this.feepostdata[key] = values;
    });

    this._feeservice
    .feeUpdate(this.interestid, this.interestpostextradata)
    .subscribe(
      (res) => {
        console.log(res + "done");
        this.spinner.hide();
        // this.router.navigate(["/pages/layout/branchview"]);
      },
      (err) => {
        this.spinner.hide();
        this._helperComponent.showToast("danger", err);
      }
    )
    .add(() => {
      this.spinner.hide();
    });

  }
  interestid(interestid: any, interestpostextradata: any) {
    throw new Error('Method not implemented.');
  }
  interestpostextradata(interestid: any, interestpostextradata: any) {
    throw new Error('Method not implemented.');
  }

}
