import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { HelpersComponent } from '../../helpers/helpers.component';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EntityParameterService {

  private headers = new HttpHeaders();


  constructor(private _http: HttpClient, private _helperComponent: HelpersComponent) { }

  private _entityserviceURL
 = "http://loadbalancer.danfishel.com/EntityParameterService";

  private handleError(errorReponse: HttpErrorResponse) {

    console.log('Client side error ', errorReponse.error.message)

    return throwError(errorReponse.error.message)

}
//Transaction code master
getalltrans(): Observable<any> {  
  return this._http.get<any>(this._entityserviceURL
 + '/api/v1/getall_transaction_code_master');  
}  

gettrans(event_id: string): Observable<any> {  
  this.headers.append('Content-Type', 'application/json');
  this.headers.append('Accept', 'application/json');
  this.headers.append('Access-Control-Allow-Headers', '*');
  return this._http.get<any>(this._entityserviceURL
 + '/api/v1/get_transaction_code_master/' + event_id, {headers: this.headers});  
}  
posttrans(formData): Observable<any> {
  this.headers.append('Content-Type', 'application/json');
  this.headers.append('Accept', 'application/json');
  this.headers.append('Access-Control-Allow-Headers', '*');
  console.log(formData);
  return this._http.post<any>(this._entityserviceURL
 +'/api/v1/transaction_code_master',
  formData,{ headers: this.headers});
}  
transactionUpdate(event_id: string, transEditData): Observable<any> {  
  this.headers.append('Content-Type', 'application/json');
  this.headers.append('Accept', 'application/json');
  this.headers.append('Access-Control-Allow-Headers', '*');
  // const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) };  
  return this._http.put<any>(this._entityserviceURL
 + '/api/v1/update_transaction_code_master/' + event_id,  transEditData, {headers: this.headers});  
}  
deletetrans(event_id: string): Observable<any> {  
  const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) };  
  return this._http.delete<any>(this._entityserviceURL
 + '' +event_id,  
httpOptions);  
} 

//serviceNamemaster
get_all_serviceNamemaster(): Observable<any> {  
  return this._http.get<any>(this._entityserviceURL + '/api/v1/getall_servicemaster');  
}  

getserviceNamemaster(service_name_id): Observable<any> {
  this.headers.append('Content-Type', 'application/json');
  this.headers.append('Accept', 'application/json');
  this.headers.append('Access-Control-Allow-Headers', '*');  
  return this._http.get<any>(this._entityserviceURL + '/api/v1/get_servicemaster/' + service_name_id,{ headers: this.headers});  
}  
postserviceNamemaster(formData): Observable<any> {  
  this.headers.append('Content-Type', 'application/json');
  this.headers.append('Accept', 'application/json');
  this.headers.append('Access-Control-Allow-Headers', '*');
  console.log(formData);
  
  return this._http.post<any>(this._entityserviceURL + '/api/v1/servicemaster',  
  formData,{ headers: this.headers});  
}  
serviceNamemasterUpdate(service_name_id: string, masterData): Observable<any> {  
  this.headers.append('Content-Type', 'application/json');
  this.headers.append('Accept', 'application/json');
  this.headers.append('Access-Control-Allow-Headers', '*'); 
  return this._http.put<any>(this._entityserviceURL + '/api/v1/update_servicemaster/'+service_name_id,  masterData,{ headers: this.headers});  
}  
deleteserviceNamemaster(service_name_id): Observable<any> {  
  const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) };  
  return this._http.delete<any>(this._entityserviceURL + '/api/v1/delete_servicemaster/'+service_name_id,  
httpOptions);  
}  

 //globalParameters
 get_all_globalParameters(): Observable<any> {  
  return this._http.get<any>(this._entityserviceURL + '');  
}  

getglobalParameters(): Observable<any> {  
  return this._http.get<any>(this._entityserviceURL + ''  );  
}  
postglobalParameters(formData): Observable<any> {  
  this.headers.append('Content-Type', 'application/json');
  this.headers.append('Accept', 'application/json');
  this.headers.append('Access-Control-Allow-Headers', '*');
  console.log(formData);
  
  return this._http.post<any>(this._entityserviceURL + '',  
  formData,{ headers: this.headers});  
}  
globalParametersUpdate( formdata): Observable<any> {  
  const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) };  
  return this._http.put<any>(this._entityserviceURL + ''+  formdata, httpOptions);  
}  
deleteglobalParameters(): Observable<any> {  
  const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) };  
  return this._http.delete<any>(this._entityserviceURL + ''+  
httpOptions);  
}  


}
