<!-- <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner> -->

<nav>
  <img src="assets/images/Danfishel-logo.png" class="circle2" width="150" />
</nav>

<section class="loginContainer">
  <div class="row">
    <div class="col-md-8">
      <ngb-carousel [interval]="3000">
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <img
              src="assets/images/slide-1.png"
              class="d-block w-100"
              alt="first slide"
            />
          </div>
          <!-- <div class="carousel-caption">
            <h2>Banking Easy Now</h2>
          </div> -->
        </ng-template>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <img
              src="assets/images/slide-2.png"
              class="d-block w-100"
              alt="second slide"
            />
          </div>
          <!-- <div class="carousel-caption">
            <h2>Smart Banking with AI</h2>
          </div> -->
        </ng-template>
      </ngb-carousel>
    </div>

    <div class="col-md-4 login-form">
      <div>
        <h3>Sign-In</h3>
        <hr class="signborder" />
      </div>
      <form
        class="login-form"
        [formGroup]="loginForm"
        (ngSubmit)="onLoginSubmit()"
      >
        <div class="form-row">
          <div class="form-group col-12">
            <label for="username">User Name</label>
            <input
              type="text"
              formControlName="username"
              name="username"
              class="form-control"
              id="username"
              [ngClass]="{
                'is-invalid':
                  f?.username?.errors && (submitted || f?.username?.touched)
              }"
            />
            <div
              class="invalid-feedback"
              *ngIf="f?.username?.errors && (submitted || f?.username?.touched)"
            >
              <div *ngIf="f?.username?.errors?.required">
                Username is required!
              </div>
              <div *ngIf="f?.username?.errors?.pattern">
                Username must not include any symbol!
              </div>
            </div>
            <div class="form-row mt-3 ml-1" *ngIf="errorCardUser">
              <span style="color: red">Username is invalid!</span>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-12">
            <label for="password">Password</label>
            <input
              type="password"
              formControlName="password"
              name="password"
              class="form-control"
              id="password"
              [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
            />
            <div
              *ngIf="submitted && f.password.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.password.errors.required">
                Password is required!
              </div>
            </div>
            <div class="form-row mt-3 ml-1" *ngIf="errorCardPwd">
              <span style="color: red">Password is invalid!</span>
            </div>
          </div>
        </div>

        <!-- <div class="form-row mt-2">
                  <div class="form-group col-6">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                      <label class="form-check-label" for="inlineCheckbox1">Remember Me</label>
                    </div>
                  </div>
                  <div class="form-group col-6 text-right">
                    <a href="#" class="text-link">Forgot Password</a>
                  </div>
                </div> -->
        <!-- <div class="form-row mt-3" *ngIf="ErrorCard">
                    <span style="color: red;">There was an error trying to authenticate user</span>
                </div> -->
        <div class="form-row mt-3">
          <button [disabled]="loading" class="btn btn-block btn-submit">
            <span
              *ngIf="loading"
              class="spinner-border spinner-border-sm mr-1"
            ></span>
            Next
          </button>
        </div>
        <div class="form-row mt-3">
          <div class="register col-6">
            <a routerLink="/register" routerLinkActive="active">
              <i class="fa fa-user"> sign-up</i></a
            >
          </div>

          <div class="register col-6">
            <a routerLink="/" routerLinkActive="active">
              <i class="fa fa-lock" data-toggle="modal" data-target="#myModal"
                >Forget Password?
                <!-- <h5 data-toggle="modal" data-target="#myModal">
                                Forget Password? 
                               </h5> -->
              </i>
            </a>
            <!-- The Modal -->
            <div class="modal fade" id="myModal">
              <div class="modal-dialog">
                <div class="modal-content">
                  <!-- Modal Header -->
                  <div class="modal-header">
                    <h4 class="modal-title">RESET PASSWORD</h4>
                    <button type="button" class="close" data-dismiss="modal">
                      &times;
                    </button>
                  </div>

                  <!-- Modal body -->
                  <div class="modal-body">
                    <form>
                      <div>
                        <label for="uname">User Name:</label>
                        <input
                          type="uname"
                          nbInput
                          placeholder="user name"
                          name="user name"
                        />
                        <button
                          type="submit"
                          class="btn btn-primary text-right"
                        >
                          Send OTP
                        </button>
                      </div>
                      <br />
                      <div>
                        <label for="otp">Enter OTP :</label>
                        <input
                          type="password"
                          nbInput
                          placeholder="Enter OTP"
                        />
                      </div>
                    </form>
                  </div>

                  <!-- Modal footer -->
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-success"
                      data-dismiss="modal"
                    >
                      Reset Password
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
