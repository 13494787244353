import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../auth/components/login/authencation.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HelpersComponent } from './helpers.component';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  
  constructor(private spinner: NgxSpinnerService,
    private _helperComponent: HelpersComponent, private authenticationService: AuthenticationService) {
     
     }
   
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available

    // let currentUser = this.authenticationService.currentUserValue;
    let currentUser =   JSON.parse(localStorage.getItem('currentUser'));
    // request = request.clone({
    //   headers: new HttpHeaders( {
    //     'secret-key': '$2b$10$cmTU2rsQ44rbkt9upayjE.FDyOp3ScSY381.Ga5suBos6D7ABI76i',
    //   }),
    // });

    console.log(currentUser);

    if (currentUser ) {
      if (request.headers.has('Skip-Interceptor')) {
        request = request.clone({
          headers: new HttpHeaders( {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }),
        });
      } else {
        if (request.headers.has('Change-Content-Type')) {
          console.log('wokring');
          
          request = request.clone({
            headers: new HttpHeaders( {
              // 'Content-Type': 'application/json',
              'Access-Token': `${currentUser}`,
              'Access-Control-Allow-Headers': 'Origin',
              'Accept': 'application/json'
            }),
          });
        } else {
          request = request.clone({
            headers: new HttpHeaders( {
              'Content-Type': 'application/json',
              'Access-Token': `${currentUser}`,
              'Access-Control-Allow-Headers': 'Origin',
              'Accept': 'application/json',
              // Authorization: `Bearer ${currentUser}`
              // 'Access': `Token ${currentUser.token}`
            }),
          });
        }
      }
    }

    return next.handle(request);
  }
}
