import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormBuilder, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { BankregisterService } from '../../../pages/layout/bankregister.service';
// Import library module
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from '../../../helpers/alert.service';
import { HelpersComponent } from '../../../helpers/helpers.component';
import { BankService } from '../../../pages/bank-maintenance/bank.service'
import { Router,ActivatedRoute } from '@angular/router';
import { AdminserviceService } from '../../../pages/bank-maintenance/adminservice.service';
import { Location } from '@angular/common';
import { ToastService } from '../../../@core/shared/services/toast-service/toast.service';

@Component({
  selector: 'BKMAST001',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  providers: [NgbCarouselConfig]
})
export class RegisterComponent implements OnInit {

  //Listing all available licenses inside an Array
  cards: any = [];

  constructor
    (private _alertService: AlertService,
      config: NgbCarouselConfig,
      private formBuilder: FormBuilder,
      private _bankservice: BankregisterService,
      private spinner: NgxSpinnerService,
      private bankservice:BankService,
      private _helperComponent: HelpersComponent,
      private router: Router,
      private toastService: ToastService,
    private route:ActivatedRoute ,
    private  adminService :AdminserviceService,
    private location: Location,

  ) {
    config.interval = 8000;
    config.wrap = true;
    config.keyboard = false;
    config.pauseOnHover = true;
  }

  bankRegisterForm: FormGroup;
  submitted = false;
  status = false;
  postdata = {};

  ngOnInit(): void {
    console.log('Register');
    console.log(this.cards);

    this.adminService.get_all_license(false).subscribe(data=> {
      this.cards = data;
      console.log(this.cards);
    });
    //Registration Form Group
    this.bankRegisterForm = this.formBuilder.group({
      bank_name: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),

      bank_code: new FormControl('', [Validators.required]),
      bank_iban_code: new FormControl('', [Validators.required, Validators.maxLength(8)]),
      bank_short_name: new FormControl(''),
      license_id: this.formBuilder.array([]),
      bank_reg_mobile: new FormControl('', [Validators.required, Validators.pattern('[0-9 ]{10}$')]),
      pincode: new FormControl('', [Validators.required]),
      address_ln_1: new FormControl('', [Validators.required]),
      bank_email: new FormControl('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      address_ln_2: new FormControl('', [Validators.required]),
      address_ln_3: new FormControl(''),
      address_ln_4: new FormControl(''),
      city: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
      state: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
      country_code: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]{2}$')]),
      acceptform: new FormControl(false, [Validators.required]),
      auto_update: ['Y'],
      UI_page_id: 'BKMAST003',
      transaction_code : 'ENT_CRE_USR_101',

    });




  } 


  // function checkForm(form)
  // {
  //   ...
  //   if(!form.terms.checked) {
  //     alert("Please indicate that you accept the Terms and Conditions");
  //     form.terms.focus();
  //     return false;
  //   }
  //   return true;
  // }


  //Checkbox Event Creating
  onCheckBoxChange(e){
    const license_id: FormArray = this.bankRegisterForm.get('license_id') as FormArray;

    if (e.target.checked) {
      license_id.push(new FormControl(e.target.value));
    } else {
      const index = license_id.controls.findIndex(x => x.value === e.target.value);
      license_id.removeAt(index);
    }
  }

  changeStatus() {
    this.status = true
  }

  //Invalid fields will get error message
  get f() {
    return this.bankRegisterForm.controls;
  }

  //Bank Registering Function
  onSubmit() {

    // this.submitted = true;

    // // stop here if form is invalid
    // if (this.bankRegisterForm.invalid) {
    //     return;
    // }
    // document.getElementById("field_terms")
    // .setCustomValidity("Please indicate that you accept the Terms and Conditions");


    this.spinner.show();
    let bankRegisterFormData = this.bankRegisterForm.value;

    // Form data
    Object.entries(bankRegisterFormData).forEach(([key, values]) => {
      this.postdata[key] = values
    })
    return this.bankservice.postbankmastermaintenance(this.postdata)
      .subscribe(
        (res) => {
          this._helperComponent.showToast('success', 'Registration Completed');
          // this.toastSend('registrationSuccessful', 'success', 'Registration Completed');
          this.spinner.hide();
          this.router.navigate(['/login']);
        },
        (err) => {
          this.spinner.hide();
          // this.toastSend('warning','danger', err);
          this._helperComponent.showErrorToast('danger', err);
        }).
        //adding loader and navigate to login page
      add(() => {
        // this.spinner.hide();
        // this.router.navigate(['/login']);
      });

  }

//Pop up for Confirming the selections
  licenseMaintenance(){

    //this.spinner.show();
     alert('Are you Sure, Want to purchase this license');
     this.submitted = true;
     document.getElementById('closeLicenceModal').click();
     // stop here if form is invalid

    if (this.submitted) {

      return this.onSubmit();

    }

  }

  back(): void {
    // this.location.back();
    document.getElementById('closeLicenceModal').click();
}
  /**
   * Returns the form controls
   */
  get getFormValidation() {
    return this.bankRegisterForm.controls;
  }
  /**
   *
   * @param code to send toastr message as per response from backend
   */
  toastSend(code,status, errorMessage?) {
    switch (code) {
      case 'registrationSuccessful': {
        this.toastService.showSuccess('Registration Successful', 'Bank registration completed successfully');
        break;
      }
      case 'warning': {
        // this.toastService.showWarning('Bad Request', errorMessage);
        break;
      }
      default: {
        this.toastService.showError('Something went wrong',
        'Try after sometime time or check your internet connection');
        break;
      }
    }
  }
}
