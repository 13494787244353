<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">
      <div>Powered By</div>
      <img src="../../../../assets/images/Danfishel-logo.png" />
    </a>
  </div>
  <nb-select
    [selected]="currentTheme"
    (selectedChange)="changeTheme($event)"
    status="primary"
  >
    <nb-option *ngFor="let theme of themes" [value]="theme.value">
      {{ theme.name }}</nb-option
    >
  </nb-select>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action
      class="control-item"
      icon="email-outline"
      link="/pages/messagesettings/emailtemplate"
    >
    </nb-action>
    <nb-action
      class="control-item"
      icon="bell-outline"
      link="/pages/notification/notifytemplate"
      badgeText="99+"
      badgePosition="top right"
      badgeStatus="info"
    ></nb-action>

    <!-- <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <nb-user [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" [name]="user?.name">
      </nb-user>
    </nb-action> -->
    <nb-action>
      <p class="bank-name mt-2" nbButton status="basic">
        {{ bankName }}
      </p>
    </nb-action>
    <nb-action *ngIf="showCountryName">
      <p class="mt-2">
        {{ countryName }}
      </p>
    </nb-action>
    <nb-action *ngIf="showCheckout">
      <button class="btn" (click)="checkoutUser()">Check Out</button>
    </nb-action>
  </nb-actions>
</div>
