import { Injectable, SkipSelf } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { JsonPipe } from '@angular/common';
import { LoginUser } from './login-user';
import { HelpersComponent } from '../../../helpers/helpers.component';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  // epin(value: any, bodyData: any) {
  //   throw new Error('Method not implemented.');
  // }
  isLogin = false;
  roleAs: string;
  
  private currentUserSubject: BehaviorSubject<LoginUser>;
  public currentUser: Observable<LoginUser>;
  private headers = new HttpHeaders();
  apiUrl: string = 'http://loadbalancer.danfishel.com/UserService/';

  // apiUrl: string = 'http://0.0.0.0:4003/api/v1/login';
     constructor(private spinner: NgxSpinnerService,
    private _helperComponent: HelpersComponent, private _http: HttpClient,
    private router: Router) {

    this.currentUserSubject = new BehaviorSubject<LoginUser>(

      JSON.parse(localStorage.getItem('currentUser'))
    )
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): LoginUser {
    return this.currentUserSubject.value;
  }


  // bankmastermaintenanceUpdate(bank_id, masterData): Observable<any> {
  //   this.headers.append("Content-Type", "application/json");
  //   this.headers.append("Accept", "application/json");
  //   this.headers.append("Access-Control-Allow-Headers", "*");
  //   console.log(masterData);

  //   return this._http.put<any>(
  //     this._bankServiceURL + "/api/v1/update_bankmaintmst/" + bank_id,
  //     masterData,
  //     { headers: this.headers }
  //   );
  // }
  // return this._http.post<any>('https://api.jsonbin.io/b/61bad9db229b23312cdc48e6', );

  // login(Username, Password, bodyData): Observable<any> {
  //   this.headers.append("X-Master-Key", "$2b$10$cmTU2rsQ44rbkt9upayjE.FDyOp3ScSY381.Ga5suBos6D7ABI76i");
  //   console.log("----",this.headers);
  //   return this._http.get<any>(
  //     "https://api.jsonbin.io/b/61bad9db229b23312cdc48e6",
  //     { headers: this.headers }
  //   );
  // }

  login(Username, Password, bodyData) {
    const headers = new HttpHeaders({ Authorization: 'Basic ' + btoa(Username + ':' + Password) });
    return this._http.post<any>(this.apiUrl + 'api/v1/userloginauth', bodyData, { headers }).pipe(
      map(
        userData => {
          localStorage.setItem('currentUser', JSON.stringify(userData['access-token']));
          this.currentUserSubject.next(userData);
          return userData;
        }
      ), catchError(this._helperComponent.handleError)
    )
  }
  
  postEpin(epinData){
    console.log(this.headers);
    return this._http.post<any>(this.apiUrl + 'api/v1/userloginepincheck', epinData);
    // this.isLogin = true;
    // this.roleAs = value;
    // //localStorage.setItem('STATE', 'true');
    // localStorage.setItem('user_role_name',
    //  JSON.stringify(res['user_role_name']));
    // return of({ success: this.isLogin, role: this.roleAs });
  }

  Login(value: string) {
    this.isLogin = true;
    this.roleAs = value;
   // localStorage.setItem('STATE', 'true');
    localStorage.setItem('user_role_name', this.roleAs);
    return of({ success: this.isLogin, role: this.roleAs });
  }

  // Logout() {
  //   this.isLogin = false;
  //   this.roleAs = '';
  //  // localStorage.setItem('STATE', 'false');
  //   localStorage.setItem('user_role_name', '');
  //   return of({ success: this.isLogin, role: '' });
  // }

  isLoggedIn() {
    
    const loggedIn = localStorage.getItem('user_role_name');
    if (loggedIn == 'true')
      this.isLogin = true;
    else
      this.isLogin = false;
    return this.isLogin;
  }

  getRole() {
    this.roleAs = localStorage.getItem('user_role_name');
    return this.roleAs;
  }

  brnSelection(branchDetails){
    console.log(this.headers);
    return this._http.post<any>(this.apiUrl + 'api/v1/userattendancecheckin',branchDetails);
  }

  // checkouUser(data){
  checkouUser(formData){
    // const formData = new FormData();
    // formData.append('transaction_code', 'USR_UCI_USR_425');
    // formData.append('UI_page_id', 'USCHO002');
    console.log(formData);
    return this._http.put<any>(this.apiUrl + 'api/v1/userattendancecheckout', formData);
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('currentUser');
    localStorage.clear();
    this.currentUserSubject.next(null);
    this.router.navigate(['/login']);
  }
}